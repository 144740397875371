import {Col, Input, Label, Media} from "reactstrap";

const ProspectToggle = (props) => {
   const {setOtherUsersInfo, otherUsersInfo} = props;

   return (
      <Col sm="2">
         <Media>
            <Label className="col-form-label">Otros usuarios</Label>
            <Media body className="'text-end icon-state switch-outline">
               <Label className="switch">
                  <Input
                     type="checkbox"
                     onChange={() => setOtherUsersInfo(!otherUsersInfo)}
                  />
                  <span className="switch-state bg-info" />
               </Label>
            </Media>
         </Media>
      </Col>
   );
};

export default ProspectToggle;
