import Select from "react-select";
import {Container, Row, Col, Card, Form, FormGroup, Input} from "reactstrap";
import {Breadcrumbs} from "../../AbstractElements";
import DataTableComponent from "../Tables/DataTable/DataTableComponent";
import ZapierEditModal from "./components/Modals/ZapierEditModal";
import useZapier from "../../Hooks/zapier/useZapier";
import ToolbarButtons from "../shared/Buttons/ToolbarButtons";

const Zapier = () => {
   const {
      search,
      lead,
      isLoading,
      showModal,
      toggleEdit,
      handleInputChange,
      filteredLeads,
      setStatus,
      columns,
      STATUS,
      fetchZapier,
      fetchReport,
   } = useZapier();

   return (
      <>
         <Breadcrumbs parent="Registros" title="Zapier" mainTitle="Zapier" />
         <Container fluid={true}>
            <Card style={{minHeight: `calc(90vh - 140px)`}}>
               <div className="m-20">
                  <Row>
                     <Col sm="5">
                        <div className="product-search feature-products">
                           <Form>
                              <FormGroup className="m-0 form-group search-product">
                                 <Input
                                    className="form-control"
                                    type="text"
                                    placeholder="Buscar..."
                                    value={search}
                                    onChange={handleInputChange}
                                 />
                                 <i className="fa fa-search" />
                              </FormGroup>
                           </Form>
                        </div>
                     </Col>
                     <Col sm="3">
                        <Select
                           options={STATUS}
                           defaultValue={STATUS[0]}
                           placeholder={"Seleccionar estatus"}
                           onChange={(e) => setStatus(e?.value)}
                           isClearable
                           styles={{
                              menu: (provided) => ({
                                 ...provided,
                                 zIndex: 3,
                              }),
                           }}
                        />
                     </Col>
                     <Col sm="4">
                        <ToolbarButtons
                           filename="Zapier"
                           onExcelClick={fetchReport}
                        />
                     </Col>
                     <Col sm="12" className="m-t-10 m-b-8">
                        <DataTableComponent
                           tableColumns={columns}
                           tableRows={filteredLeads}
                           loadingData={isLoading}
                           pagination={true}
                           scrollHeight={"calc(84vh - 230px)"}
                           minHeight={`calc(82vh - 230px)`}
                        />
                     </Col>
                  </Row>
               </div>
            </Card>
         </Container>
         {showModal && (
            <ZapierEditModal
               modal={true}
               toggle={toggleEdit}
               lead={lead}
               fetchZapier={fetchZapier}
            />
         )}
      </>
   );
};

export default Zapier;
