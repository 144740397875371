import {Form, FormGroup, Label, Row, Col, Button} from "reactstrap";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import {es} from "date-fns/locale";
import useExcel from "../../../Hooks/useExcel";

const FilterRangeDate = ({
   onClose,
   onExcelClick,
   filename,
   to,
   from,
   setTo,
   setFrom,
}) => {
   const {handleExport2} = useExcel();

   const handleExcel = async () => {
      const data = await onExcelClick();
      handleExport2(data, `${filename}_${moment().format("DDMMYYYY")}`);
      onClose();
   };

   return (
      <Form>
         <FormGroup>
            <Row>
               <Col sm="12">
                  <Row style={{paddingTop: "20px"}}>
                     <Label className="col-sm-6 col-form-label">Desde:</Label>
                     <Col sm="6">
                        <ReactDatePicker
                           selected={from}
                           onChange={(date) => {
                              setFrom(date);
                           }}
                           className="form-control digits"
                           dateFormat="dd/MM/yyyy"
                           locale={es}
                        />
                     </Col>
                  </Row>
               </Col>
               <Col sm="12">
                  <Row style={{paddingTop: "20px"}}>
                     <Label className="col-sm-6 col-form-label">Hasta:</Label>
                     <Col sm="6">
                        <ReactDatePicker
                           className="form-control digits"
                           selected={to}
                           dateFormat="dd/MM/yyyy"
                           locale={es}
                           onChange={(date) => {
                              setTo(date);
                           }}
                        />
                     </Col>
                  </Row>
               </Col>
            </Row>
         </FormGroup>
         <div className="w-100 d-flex justify-content-end btn-showcase">
            <Button color="secondary" onClick={onClose} className="m-r-10">
               Cancelar
            </Button>
            <Button
               color="primary"
               onClick={handleExcel}
               style={{
                  minWidth: 120,
                  marginRight: 10,
               }}
            >
               Exportar
            </Button>
         </div>
      </Form>
   );
};

export default FilterRangeDate;
