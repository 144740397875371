import { useState } from 'react';
import Request from '../../api/httpClient';
import { toast } from 'react-toastify';

const useAvailabilityEditForm = (departamento, toggle, reload) => {
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

   const [estados] = useState([
      { value: 'V', label: 'Vendido' },
      { value: 'D', label: 'Disponible' },
      { value: 'A', label: 'Apartado' },
   ]);

   const [preventas] = useState([
      { value: 0, label: 'NO' },
      { value: 1, label: 'Si' },
   ]);

   const [precio, setPrecio] = useState(departamento.precio);
   const [estado, setEstado] = useState(
      estados.find((item) => item.value === departamento.estado)
   );
   const [preventa, setPreventa] = useState(
      preventas.find((item) => item.value === departamento.preventa)
   );

   const request = new Request();

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitDisabled(true);

      let data = {
         id_departamento: departamento.pkdepto,
         precio: precio,
         estado: estado.value,
         preventa: preventa.value,
      };

      try {
         const response = await request.post('/disponibilidad/departamento/update', data);

         if (response && !response.error) {
            toggle();
            reload('Departamento editado con éxito ');
         } else {
            throw new Error(response.message);
         }
      } catch (error) {
         toast.error(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      } finally {
         setIsSubmitDisabled(false);
      }
   };

   return {
      isSubmitDisabled,
      precio,
      setPrecio,
      estado,
      setEstado,
      preventa,
      setPreventa,
      estados,
      preventas,
      handleSubmit,
   };
};

export default useAvailabilityEditForm;
