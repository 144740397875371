import React from "react";
import {Col} from "react-bootstrap";
import {Form, FormGroup, Input, Label, Row} from "reactstrap";
import CommonModal from "../UiKits/Modals/common/modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Btn} from "../../AbstractElements";
import Select from "react-select";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import useAvailabilityEditForm from "../../Hooks/availability/useAvailabilityEditForm";

const DisponibilidadFormEdit = ({
  modal,
  title,
  toggle,
  size,
  reload,
  departamento,
}) => {
  const {
    isSubmitDisabled,
    precio,
    setPrecio,
    estado,
    setEstado,
    preventa,
    setPreventa,
    estados,
    preventas,
    handleSubmit,
  } = useAvailabilityEditForm(departamento, toggle, reload);

  return (
    <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
      <Modal isOpen={modal} toggle={toggle} size={size} centered>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody style={{paddingTop: "2px", paddingBottom: "2px"}}>
            <Row>
              <Col sm="12" md="12">
                <div className="project-box" style={{paddingBottom: "5px"}}>
                  <div>
                    <div>
                      <FormGroup className="row">
                        <Label className="col-form-label text-start">
                          Precio:
                        </Label>
                        <Col xl="12">
                          <Input
                            value={precio}
                            id="precio"
                            type="number"
                            onChange={(e) => {
                              setPrecio(e.target.value);
                            }}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-form-label text-start">
                          Estado:
                        </Label>
                        <Col xl="12">
                          <Select
                            value={estado}
                            options={estados}
                            placeholder={"Seleccione..."}
                            onChange={(estado) => setEstado(estado)}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-form-label text-start">
                          Preventa:
                        </Label>

                        <Col xl="12">
                          <Select
                            value={preventa}
                            options={preventas}
                            placeholder={"Seleccione..."}
                            onChange={(preventa) => setPreventa(preventa)}
                            required
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Btn attrBtn={{color: "secondary", onClick: toggle}}>Cancelar</Btn>
            <Btn
              attrBtn={{
                type: "submit",
                color: "primary",
                disabled: isSubmitDisabled,
              }}
            >
              {isSubmitDisabled ? "Guardando..." : "Guardar"}
            </Btn>
          </ModalFooter>
        </Form>
      </Modal>
    </CommonModal>
  );
};

export default DisponibilidadFormEdit;
