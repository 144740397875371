import React, { Fragment } from 'react';
import { Container, Row, Card } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import DataTableComponent from '../Tables/DataTable/DataTableComponent';
import { useReminders } from '../../Hooks/reminders/useReminders';

const RecordatoriosPage = () => {
   const { recordatorios, loadingRecordatorios, tableColumns, conditionalRowStyles } =
      useReminders();

   return (
      <Fragment>
         <Breadcrumbs parent="Gestión" title="Recordatorios" mainTitle="Recordatorios" />
         <Container fluid={true}>
            <Row>
               <Card style={{ minHeight: `calc(92vh - 142px)` }}>
                  <div>
                     <DataTableComponent
                        tableColumns={tableColumns}
                        tableRows={recordatorios}
                        loadingData={loadingRecordatorios}
                        pagination={true}
                        conditionalRowStyles={conditionalRowStyles}
                        scrollHeight={'calc(90vh - 160px)'}
                        minHeight={`calc(86vh - 160px)`}
                     />
                  </div>
               </Card>
            </Row>
         </Container>
      </Fragment>
   );
};

export default RecordatoriosPage;
