import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {Form, FormGroup, Input, Label, Row} from "reactstrap";
import CommonModal from "../../UiKits/Modals/common/modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Btn} from "../../../AbstractElements";
import Request from "../../../api/httpClient";
import Select from "react-select";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {toast} from "react-toastify";
import moment from "moment";

const request = new Request();

const BrokerCreateModal = ({
   modal,
   title,
   toggle,
   size,
   reload,
   fuentes,
   loadingFuentes,
}) => {
   const userId = localStorage.getItem("user_id") || 0;
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const [nombre, setNombre] = useState("");
   const [email, setEmail] = useState("");
   const [telefono, setTelefono] = useState("");
   const [ciudad, setCiudad] = useState("");
   const [fuente, setFuente] = useState(fuentes[0]);

   useEffect(() => {
      if (modal) {
         LimpiarDatos();
      }
   }, [modal]);

   const LimpiarDatos = () => {
      setNombre("");
      setEmail("");
      setCiudad("");
      setTelefono("");
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitDisabled(true);

      const fechaHoy = moment().format("YYYY-MM-DD");

      let data = {
         nombre_usuario: localStorage.getItem("Name") || "",
         id_usuario: localStorage.getItem("user_id") || 0,
         cliente: nombre,
         email: email,
         telefono: telefono,
         ciudad: ciudad,
         budget: null,
         comentarios: null,
         interes: null,
         origen: fuente.value,
         origen_otro: null,
         broker: true,
         fechaVencimiento: moment(fechaHoy)
            .add(45, "days")
            .format("YYYY-MM-DD"),
         aprobado: 1,
         importante: 0,
         calificacion: 3,
         id_desarrollo: 5,
         id_embudo: 9,
         posponer: null,
         register_id: userId,
      };

      const response = await request.post("/prospectos/prospecto/create", data);

      if (response && !response.error) {
         toggle();
         setIsSubmitDisabled(false);
         reload(true, "Broker creado con éxito ");
      } else {
         setIsSubmitDisabled(false);
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   return (
      <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
         <Modal isOpen={modal} toggle={toggle} size={size} centered>
            <Form onSubmit={handleSubmit}>
               <ModalHeader toggle={toggle}>{title}</ModalHeader>
               <ModalBody style={{paddingTop: "2px", paddingBottom: "2px"}}>
                  <Row>
                     <Col sm="12" md="12">
                        <div
                           className="project-box"
                           style={{paddingBottom: "5px"}}
                        >
                           <div>
                              <div>
                                 <FormGroup className="row">
                                    <Col xl="6" sm="9">
                                       <Label className="col-form-label text-start">
                                          Nombre:
                                       </Label>
                                       <Input
                                          id="nombre"
                                          type="text"
                                          onChange={(e) => {
                                             setNombre(e.target.value);
                                          }}
                                          required
                                       />
                                    </Col>
                                    <Col xl="6" sm="9">
                                       <Label className="col-form-label text-start">
                                          Email:
                                       </Label>
                                       <Input
                                          id="email"
                                          type="email"
                                          onChange={(e) => {
                                             setEmail(e.target.value);
                                          }}
                                          required
                                       />
                                    </Col>
                                 </FormGroup>
                                 <FormGroup className="row">
                                    <Col xl="6" sm="9">
                                       <Label className="col-form-label text-start">
                                          Teléfono:
                                       </Label>
                                       <Input
                                          id="telefono"
                                          type="tel"
                                          value={telefono}
                                          minLength={10}
                                          maxLength={15}
                                          onChange={(e) => {
                                             const telefono =
                                                e.target.value.replace(
                                                   /[^0-9]/g,
                                                   ""
                                                );
                                             setTelefono(telefono);
                                          }}
                                          required
                                       />
                                    </Col>
                                    <Col xl="6" sm="9">
                                       <Label className="col-form-label text-start">
                                          Ciudad:
                                       </Label>
                                       <Input
                                          id="ciudad"
                                          type="text"
                                          onChange={(e) => {
                                             setCiudad(e.target.value);
                                          }}
                                       />
                                    </Col>
                                 </FormGroup>
                                 <FormGroup className="row">
                                    <Col xl="6" sm="9">
                                       <Label className="col-form-label text-start">
                                          Fuente de contacto:
                                       </Label>
                                       <Select
                                          value={fuente}
                                          options={fuentes}
                                          placeholder={"Seleccione..."}
                                          onChange={(fuente) =>
                                             setFuente(fuente)
                                          }
                                          isLoading={loadingFuentes}
                                          required
                                       />
                                    </Col>
                                 </FormGroup>
                              </div>
                           </div>
                        </div>
                     </Col>
                  </Row>
               </ModalBody>
               <ModalFooter>
                  <Btn attrBtn={{color: "secondary", onClick: toggle}}>
                     Cancelar
                  </Btn>
                  <Btn
                     attrBtn={{
                        type: "submit",
                        color: "primary",
                        disabled: isSubmitDisabled,
                     }}
                  >
                     {isSubmitDisabled ? "Guardando..." : "Guardar"}
                  </Btn>
               </ModalFooter>
            </Form>
         </Modal>
      </CommonModal>
   );
};

export default BrokerCreateModal;
