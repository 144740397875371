import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, Image } from '../../../AbstractElements';
import Logo from '../../../assets/images/login/logo-login.png';
import Request from '../../../api/httpClient';
import { toast } from 'react-toastify';

const CreatePwd = ({ logoClassMain }) => {
   const [togglePassword, setTogglePassword] = useState(false);
   const [password, setPassword] = useState('');
   const [passwordConfirm, setPasswordConfirm] = useState('');
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const request = new Request();
   const history = useNavigate();
   const { id, type, token } = useParams();

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitDisabled(true);
      if (password === passwordConfirm) {
         let data = {
            id_usuario: +id,
            password: btoa(password),
            tipo: type,
            codigo: token,
         };

         const response = await request.post('/users/new/password', data);

         if (response.changed) {
            setIsSubmitDisabled(false);
            toast.success(response.message, {
               position: toast.POSITION.BOTTOM_RIGHT,
               autoClose: 2000,
               onClose: () => history('/'),
            });
         } else {
            setIsSubmitDisabled(false);
            toast.error(response.message, {
               position: toast.POSITION.BOTTOM_RIGHT,
               autoClose: 2000,
            });
         }
      } else {
         setIsSubmitDisabled(false);
      }
   };

   return (
      <Fragment>
         <section>
            <Container fluid={true} className="p-0 login-page">
               <Row className="m-0">
                  <Col xl="12 p-0">
                     <div className="login-card">
                        <div>
                           <div>
                              <Link
                                 className={`logo ${logoClassMain ? logoClassMain : ''}`}
                                 to={process.env.PUBLIC_URL}>
                                 <Image
                                    attrImage={{
                                       className: 'img-fluid for-light',
                                       src: Logo,
                                       alt: 'logo',
                                    }}
                                 />
                              </Link>
                           </div>
                           <div className="login-main">
                              <Form
                                 className="theme-form login-form"
                                 onSubmit={handleSubmit}>
                                 <H4>Crear contraseña</H4>
                                 <FormGroup className="position-relative">
                                    <Label className="m-0 col-form-label">
                                       Nueva contraseña
                                    </Label>
                                    <div className="position-relative">
                                       <Input
                                          className="form-control"
                                          type={togglePassword ? 'text' : 'password'}
                                          name="login[password]"
                                          value={password}
                                          onChange={(e) => {
                                             setPassword(e.target.value);
                                          }}
                                          required
                                       />
                                       <div
                                          className="show-hide"
                                          onClick={() =>
                                             setTogglePassword(!togglePassword)
                                          }>
                                          {/*  <span
                              className={togglePassword ? "" : "show"}
                            ></span> */}
                                       </div>
                                    </div>
                                 </FormGroup>
                                 <FormGroup>
                                    <Label className="m-0 col-form-label">
                                       Vuelva a escribir la contraseña
                                    </Label>
                                    <Input
                                       className="form-control"
                                       type="password"
                                       name="login[password]"
                                       value={passwordConfirm}
                                       onChange={(e) => {
                                          setPasswordConfirm(e.target.value);
                                       }}
                                       required
                                    />
                                    {password &&
                                       passwordConfirm &&
                                       (password === passwordConfirm ? (
                                          <span style={{ color: 'green' }}>
                                             Las contraseñas coinciden
                                          </span>
                                       ) : (
                                          <span style={{ color: 'red' }}>
                                             Los contraseñas no coinciden
                                          </span>
                                       ))}
                                 </FormGroup>
                                 <FormGroup>
                                    <Btn
                                       attrBtn={{
                                          className: 'd-block w-100',
                                          color: 'primary',
                                          type: 'submit',
                                          disabled: isSubmitDisabled,
                                       }}>
                                       {isSubmitDisabled ? 'Cambiando...' : 'Cambiar'}
                                    </Btn>
                                 </FormGroup>
                              </Form>
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
      </Fragment>
   );
};

export default CreatePwd;
