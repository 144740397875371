// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect} from "react";
import Request from "../../api/httpClient";
import {ZapierColumns} from "../../Data/Table/ZapierColumns";

const request = new Request();

const STATUS = [
   {id: 0, label: "Todos", value: "Todos"},
   {id: 1, label: "Asignados", value: "Asignados"},
   {id: 2, label: "No asignados", value: "No asignados"},
];

const useZapier = () => {
   const [search, setSearch] = useState("");
   const [status, setStatus] = useState("");
   const [leads, setLeads] = useState([]);
   const [lead, setLead] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const [showModal, setShowModal] = useState(false);

   const toggleEdit = () => setShowModal(!showModal);

   const handleInputChange = (e) => {
      setSearch(e.target.value);
   };

   const filteredLeads = leads.filter((lead) => {
      return (
         lead.Nombre.toLowerCase().includes(search.toLowerCase()) ||
         lead.Apellido.toLowerCase().includes(search.toLowerCase()) ||
         lead.WhatsApp.toLowerCase().includes(search.toLowerCase()) ||
         lead.Email.toLowerCase().includes(search.toLowerCase())
      );
   });

   const fetchZapier = async () => {
      setLeads([]);
      setIsLoading(true);

      const selectedStatus = STATUS.find((s) => s.value === status);

      const data = {
         statusId: selectedStatus?.id || 0,
      };

      const response = await request.post("/zapier", data);

      if (response && !response.error) {
         if (response && !response.empty) {
            setLeads(response);
         } else {
            setLeads([]);
            setIsLoading(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setIsLoading(false);
   };

   const fetchReport = async () => {
      const selectedStatus = STATUS.find((s) => s.value === status);
      const data = {
         statusId: selectedStatus?.id || 0,
      };

      const response = await request.post("/zapier/report", data);
      return response;
   };

   const handleEditarProspecto = (row) => {
      setLead(row);
      toggleEdit();
   };

   const columns = ZapierColumns(handleEditarProspecto);

   useEffect(() => {
      fetchZapier();
   }, [status]);

   return {
      search,
      status,
      leads,
      lead,
      isLoading,
      showModal,
      toggleEdit,
      handleInputChange,
      filteredLeads,
      setStatus,
      handleEditarProspecto,
      columns,
      STATUS,
      fetchZapier,
      fetchReport,
   };
};

export default useZapier;
