import React from "react";
import {
   Typography,
   IconButton,
   Menu,
   MenuItem,
   ListItemIcon,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";

const UsuarioMenu = ({
   row,
   onDeleteRow,
   onUnDeleteRow,
   size,
   onEditRow,
   onEditPasswordRow,
}) => {
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };
   const deleteRow = () => {
      if (onDeleteRow) {
         setAnchorEl(null);
         onDeleteRow(row);
      }
   };
   const undeleteRow = () => {
      if (onDeleteRow) {
         setAnchorEl(null);
         onUnDeleteRow(row);
      }
   };
   const editRow = () => {
      if (onEditRow) {
         setAnchorEl(null);
         onEditRow(row);
      }
   };
   const editPasswordRow = () => {
      if (onEditPasswordRow) {
         setAnchorEl(null);
         onEditPasswordRow(row);
      }
   };

   return (
      <div>
         <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size={size}
         >
            <MoreVertIcon />
         </IconButton>
         <Menu
            id="menu"
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "center",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "center",
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
         >
            <MenuItem onClick={editRow}>
               <ListItemIcon>
                  <EditOutlinedIcon fontSize="small" color="primary" />
               </ListItemIcon>
               <Typography variant="inherit">Editar</Typography>
            </MenuItem>
            <MenuItem onClick={editPasswordRow}>
               <ListItemIcon>
                  <PasswordOutlinedIcon fontSize="small" color="primary" />
               </ListItemIcon>
               <Typography variant="inherit">Contraseña</Typography>
            </MenuItem>
            {row.state === 1 && (
               <MenuItem onClick={deleteRow}>
                  <ListItemIcon>
                     <CloseOutlinedIcon fontSize="small" color="error" />
                  </ListItemIcon>
                  <Typography variant="inherit">Desactivar</Typography>
               </MenuItem>
            )}
            {row.state === 0 && (
               <MenuItem onClick={undeleteRow}>
                  <ListItemIcon>
                     <CheckOutlinedIcon fontSize="small" color="success" />
                  </ListItemIcon>
                  <Typography variant="inherit">Activar</Typography>
               </MenuItem>
            )}
         </Menu>
      </div>
   );
};

export default UsuarioMenu;
