import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Request from '../../api/httpClient';
import RemindersColumns from '../../Data/Table/RemindersColumns';

const request = new Request();

export const useReminders = () => {
   const [recordatorios, setRecordatorios] = useState([]);
   const [loadingRecordatorios, setLoadingRecordatorios] = useState(false);

   const getRecordatorios = async () => {
      setRecordatorios([]);
      setLoadingRecordatorios(true);
      const data = {
         id_usuario: localStorage.getItem('user_id') || 0,
      };

      const response = await request.post('/prospectos/recordatorios', data);
      if (response && !response.error) {
         if (response && !response.empty) {
            setRecordatorios(response);
         } else {
            setRecordatorios([]);
            setLoadingRecordatorios(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setLoadingRecordatorios(false);
   };

   const handleEliminarRecordatorio = async (row) => {
      let data = {
         id_recordatorio: row.pkbinnacle,
      };

      const response = await request.post(
         '/prospectos/recordatorios/recordatorio/delete',
         data
      );
      if (response && !response.error) {
         reload('Recordatorio eliminado con éxito ');
      } else {
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   const reload = (message) => {
      toast.success(message, {
         position: toast.POSITION.BOTTOM_RIGHT,
         autoClose: 2000,
      });
      getRecordatorios();
   };

   const tableColumns = RemindersColumns({ handleEliminarRecordatorio });

   const conditionalRowStyles = [
      {
         when: (row) => new Date(row.fEvento) < new Date(),
         style: {
            color: 'red',
         },
      },
   ];

   useEffect(() => {
      getRecordatorios();
   }, []);

   return {
      recordatorios,
      loadingRecordatorios,
      tableColumns,
      conditionalRowStyles,
   };
};
