import "react-bootstrap-typeahead/css/Typeahead.css";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import ProspectCreateForm from "../Forms/ProspectCreateForm";

const ProspectCreateModal = ({
   modal,
   title,
   toggle,
   reload,
   p_desarrollos,
   p_desarrollo,
   fuentes,
   loadingFuentes,
   etapas,
}) => {
   return (
      <Modal isOpen={modal} toggle={toggle} size="xl" centered>
         <ModalHeader toggle={toggle}>{title}</ModalHeader>
         <ModalBody
            style={{
               minHeight: "500px",
            }}
         >
            <ProspectCreateForm
               modal={modal}
               toggle={toggle}
               reload={reload}
               p_desarrollos={p_desarrollos}
               p_desarrollo={p_desarrollo}
               fuentes={fuentes}
               loadingFuentes={loadingFuentes}
               etapas={etapas}
            />
         </ModalBody>
      </Modal>
   );
};

export default ProspectCreateModal;
