import React from "react";
import {
   Typography,
   IconButton,
   Menu,
   MenuItem,
   ListItemIcon,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const BrokersMenu = ({row, onDeleteRow, size, onEditRow}) => {
   const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };
   const deleteRow = () => {
      if (onDeleteRow) {
         setAnchorEl(null);
         onDeleteRow(row);
      }
   };

   return (
      <div>
         <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            size={size}
         >
            <MoreVertIcon />
         </IconButton>
         <Menu
            id="menu"
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "center",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "center",
            }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
         >
            <MenuItem onClick={deleteRow}>
               <ListItemIcon>
                  <CloseOutlinedIcon fontSize="small" color="error" />
               </ListItemIcon>
               <Typography variant="inherit">Eliminar</Typography>
            </MenuItem>
         </Menu>
      </div>
   );
};

export default BrokersMenu;
