import React from "react";
import {Form, FormGroup, Input, Label, Row, Col} from "reactstrap";
import {Btn} from "../../../../AbstractElements";
import Select from "react-select";
import moment from "moment";
import useProspectEditForm from "../../../../Hooks/prospects/useProspectEditForm";

const ProspectEditForm = (props) => {
  const {
    isSubmitDisabled,
    nombre,
    setNombre,
    email,
    setEmail,
    telefono,
    setTelefono,
    ciudad,
    setCiudad,
    desarrollo,
    desarrollos,
    setDesarrollo,
    departamentos,
    departamentosSelec,
    setDepartamentosSelec,
    loadingDepartamentos,
    calificacion,
    calificaciones,
    setCalificacion,
    fuentes,
    fuente,
    setFuente,
    etapas,
    etapa,
    setEtapa,
    posponer,
    setPosponer,
    posponerOpciones,
    emailRecuperacion1_fecha,
    emailRecuperacion2_fecha,
    emailRecuperacion3_fecha,
    comentarios,
    setComentarios,
    toggle,
    handleSubmit,
  } = useProspectEditForm(props);

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <div className="project-form-modal">
          <FormGroup>
            <Row>
              <Col xl="4" sm="12">
                <Label className="col-form-label text-start">Nombre:</Label>
                <Input
                  value={nombre}
                  id="nombre"
                  type="text"
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                  required
                />
              </Col>
              <Col xl="4" sm="12">
                <Label className="col-form-label text-start">Email:</Label>
                <Input
                  value={email}
                  id="email"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </Col>
              <Col xl="4" sm="12">
                <Label className="col-form-label text-start">Teléfono:</Label>
                <Input
                  value={telefono}
                  id="telefono"
                  type="tel"
                  minLength={10}
                  maxLength={15}
                  onChange={(e) => {
                    const telefono = e.target.value.replace(/[^0-9]/g, "");
                    setTelefono(telefono);
                  }}
                  required
                />
              </Col>
              <Col xl="4" sm="12">
                <Label className="col-form-label text-start">Ciudad:</Label>
                <Input
                  value={ciudad}
                  id="ciudad"
                  type="text"
                  onChange={(e) => {
                    setCiudad(e.target.value);
                  }}
                />
              </Col>
              <Col xl="4" sm="12">
                <Label className="col-form-label text-start">Desarrollo:</Label>
                <Select
                  value={desarrollo}
                  options={desarrollos}
                  placeholder={"Seleccione..."}
                  onChange={(desarr) => setDesarrollo(desarr)}
                  isDisabled={true}
                />
              </Col>
              <Col xl="4" sm="12" style={{zIndex: 999}}>
                <Label className="col-form-label text-start">
                  Interesados en:
                </Label>
                <Select
                  options={departamentos}
                  value={departamentosSelec}
                  onChange={(depts) => setDepartamentosSelec(depts)}
                  placeholder={"Seleccione..."}
                  isLoading={loadingDepartamentos}
                  // isClearable
                />
              </Col>
              <Col xl="4" sm="12" style={{zIndex: 998}}>
                <Label className="col-form-label text-start">
                  Calificación:
                </Label>
                <Select
                  value={calificacion}
                  options={calificaciones}
                  placeholder={"Seleccione..."}
                  onChange={(calificacion) => setCalificacion(calificacion)}
                  required
                />
              </Col>
              <Col xl="4" sm="12" style={{zIndex: 997}}>
                <Label className="col-form-label text-start">
                  Fuente de contacto:
                </Label>
                <Select
                  value={fuente}
                  options={fuentes}
                  placeholder={"Seleccione..."}
                  onChange={(fuente) => setFuente(fuente)}
                  required
                />
              </Col>
              <Col xl="4" sm="12" style={{zIndex: 996}}>
                <Label className="col-form-label text-start">Etapa:</Label>
                <Select
                  value={etapa}
                  options={etapas}
                  placeholder={"Seleccione..."}
                  onChange={(etapa) => setEtapa(etapa)}
                  required
                />
              </Col>
              {etapa && etapa.label === "Seguimiento" && (
                <Col xl="4" sm="12" style={{zIndex: 995}}>
                  <Label className="col-form-label text-start">
                    Posponer hasta:
                  </Label>
                  <Select
                    value={posponer}
                    options={posponerOpciones}
                    placeholder={"Seleccione..."}
                    onChange={(posponer) => setPosponer(posponer)}
                    // required
                  />
                </Col>
              )}
            </Row>
          </FormGroup>
          <Form>
            <Row>
              <Col sm="6">
                <div className="checkbox">
                  <Input
                    id="email_recuperacion1"
                    type="checkbox"
                    checked={emailRecuperacion1_fecha !== null ? true : false}
                    readOnly
                  />
                  <Label className="text-muted" for="email_recuperacion1">
                    1er Correo de Recuperación
                  </Label>
                </div>
              </Col>
              <Col sm="6">
                {emailRecuperacion1_fecha != null && (
                  <Label className="text-muted p-10 font-success">
                    Enviado el día{" "}
                    {moment(emailRecuperacion1_fecha).format("DD-MM-YYYY")}
                  </Label>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <div className="checkbox">
                  <Input
                    id="email_recuperacion2"
                    type="checkbox"
                    checked={emailRecuperacion2_fecha !== null ? true : false}
                    readOnly
                  />
                  <Label className="text-muted" for="email_recuperacion2">
                    2do Correo de Recuperación
                  </Label>
                </div>
              </Col>
              <Col sm="6">
                {emailRecuperacion2_fecha != null && (
                  <Label className="text-muted p-10 font-success">
                    Enviado el día{" "}
                    {moment(emailRecuperacion2_fecha).format("DD-MM-YYYY")}
                  </Label>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <div className="checkbox">
                  <Input
                    id="email_recuperacion3"
                    type="checkbox"
                    checked={emailRecuperacion3_fecha !== null ? true : false}
                    readOnly
                  />
                  <Label className="text-muted" for="email_recuperacion3">
                    3er Correo de Recuperación
                  </Label>
                </div>
              </Col>
              <Col sm="6">
                {emailRecuperacion3_fecha != null && (
                  <Label className="text-muted p-10 font-success">
                    Enviado el día{" "}
                    {moment(emailRecuperacion3_fecha).format("DD-MM-YYYY")}
                  </Label>
                )}
              </Col>
            </Row>
            {/* <Col xl="12" sm="12">
                     <div className="checkbox">
                        <Input
                           id="broker"
                           type="checkbox"
                           checked={broker}
                           onChange={(e) => setBroker(e.target.checked)}
                        />
                        <Label className="text-muted" for="broker">
                           Este prospecto es un BROKER
                        </Label>
                     </div>
                  </Col> */}
          </Form>
          <div>
            <Col sm="12">
              <Label className="col-form-label text-start">Comentarios:</Label>
              <Input
                value={comentarios}
                id="comentarios"
                type="textarea"
                className="form-control"
                rows="2"
                onChange={(e) => {
                  setComentarios(e.target.value);
                }}
              />
            </Col>
          </div>
        </div>
      </Row>
      <div className="text-end btn-showcase" style={{marginBottom: "-30px"}}>
        <Btn
          attrBtn={{
            color: "secondary",
            onClick: toggle,
            className: "m-r-10",
          }}
        >
          Cancelar
        </Btn>
        <Btn
          attrBtn={{
            type: "submit",
            color: "primary",
            disabled: isSubmitDisabled,
          }}
        >
          {isSubmitDisabled ? "Guardando..." : "Guardar"}
        </Btn>
      </div>
    </Form>
  );
};

export default ProspectEditForm;
