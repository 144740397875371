import {Button} from "reactstrap";
import PeopleIcon from "@mui/icons-material/People";

const ProspectListButton = ({row, onProspectListRow}) => {
   const prospectListRow = () => {
      if (onProspectListRow) {
         onProspectListRow(row);
      }
   };
   return (
      <Button
         color="dlk"
         onClick={prospectListRow}
         style={{
            width: 50,
            paddingRight: 2,
            paddingLeft: 2,
            paddingTop: 4,
            paddingBottom: 4,
            fontSize: 4,
         }}
      >
         <PeopleIcon />
      </Button>
   );
};

export default ProspectListButton;
