import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Request from '../../api/httpClient';
import EmbudosContext from '../../_helper/Embudos';

const request = new Request();

const useFunnels = () => {
   const { embudos, setEmbudos, getEmbudos } = useContext(EmbudosContext);
   const [loadingAction, setLoadingAction] = useState(false);
   const [asunto_accion, setAsuntoAccion] = useState('');
   const [cuerpo_accion, setCuerpoAccion] = useState('');

   const handleEliminarEmbudo = async (row) => {
      const result = await Swal.fire({
         title: 'Está seguro de eliminar este embudo?',
         text: 'Se eliminará el embudo: ' + row.funnelName,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Eliminar',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      });

      if (result.isConfirmed) {
         let data = { id_embudo: row.funnelId };
         const response = await request.post('/embudos/embudo/delete', data);
         if (response && !response.error) {
            Swal.fire('Eliminado!', 'Este embudo ha sido eliminado', 'success');
            getEmbudos();
         } else {
            toast.error(response.message, {
               position: toast.POSITION.BOTTOM_RIGHT,
               autoClose: 2000,
            });
         }
      }
   };

   const handleNuevoEmbudo = async (nuevoEmbudo) => {
      const result = await Swal.fire({
         title: 'Está seguro de crear un nuevo embudo?',
         text: 'Se creará el embudo: ' + nuevoEmbudo,
         icon: 'info',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Crear',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      });

      if (result.isConfirmed) {
         let data = { nombre_embudo: nuevoEmbudo };
         const response = await request.post('/embudos/embudo/create', data);
         if (response && !response.error) {
            Swal.fire('Creado!', 'Se ha creado un nuevo embudo', 'success');
            getEmbudos();
         } else {
            toast.error(response.message, {
               position: toast.POSITION.BOTTOM_RIGHT,
               autoClose: 2000,
            });
         }
      }
   };

   const handleEditarEmbudo = async (modifiedIndex) => {
      const embudo = embudos[modifiedIndex];

      const result = await Swal.fire({
         title: 'Está seguro de editar este embudo?',
         text: 'Se editará el embudo: ' + embudo.funnelName,
         icon: 'info',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Editar',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      });

      if (result.isConfirmed) {
         let data = {
            id_embudo: embudo.funnelId,
            nombre_embudo: embudo.funnelName,
            orden_embudo: embudo.funnelOrder,
         };

         const response = await request.post('/embudos/embudo/update', data);
         if (response && !response.error) {
            Swal.fire('Editado!', 'Embudo editado con éxito', 'success');
            getEmbudos();
         } else {
            toast.error(response.message, {
               position: toast.POSITION.BOTTOM_RIGHT,
               autoClose: 2000,
            });
         }
      }
   };

   const handleGetAction = async (row) => {
      setAsuntoAccion('');
      setCuerpoAccion('');
      setLoadingAction(true);

      let data = { id_embudo: row.funnelId };
      const response = await request.post('/embudos/embudo/action/get', data);
      if (response && !response.error) {
         if (response.length > 0) {
            setAsuntoAccion(response[0].funnelSubject);
            setCuerpoAccion(response[0].funnelAction);
         }
      } else {
         console.error(response.message);
      }
      setLoadingAction(false);
   };

   const handleUpdateAction = async (selectedButtonIndex) => {
      let data = {
         id_embudo: embudos[selectedButtonIndex].funnelId,
         asunto: asunto_accion,
         cuerpo: cuerpo_accion,
      };
      const response = await request.post('/embudos/embudo/action/update', data);
      if (response && !response.error) {
         toast.success('Se guardaron los cambios', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      } else {
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   return {
      embudos,
      setEmbudos,
      loadingAction,
      asunto_accion,
      cuerpo_accion,
      setAsuntoAccion,
      setCuerpoAccion,
      handleEliminarEmbudo,
      handleNuevoEmbudo,
      handleEditarEmbudo,
      handleGetAction,
      handleUpdateAction,
   };
};

export default useFunnels;
