import React from "react";
import moment from "moment";
import BrokersMenu from "../../Components/brokers/components/BrokersMenu";
import EditButton from "../../Components/shared/Buttons/EditButton";

export const BrokersColumns = (
   handleEditarProspecto,
   handleEliminarProspecto,
   otrosUsuariosInfo
) => [
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Registro</div>,
      excelname: "Registro",
      minWidth: "120px",
      width: 15,
      selector: (row) => moment(row.registerclient),
      cell: (row) => `${moment(row.registerclient).format("DD-MM-YYYY")}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Nombre</div>,
      excelname: "Nombre",
      minWidth: "150px",
      width: 25,
      selector: (row) => `${row.client}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Correo</div>,
      excelname: "Teléfono",
      minWidth: "120px",
      width: 15,
      selector: (row) => `${row.emailclient}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Teléfono</div>,
      excelname: "Teléfono",
      minWidth: "120px",
      width: 15,
      selector: (row) => `${row.phoneclient}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Asesor</div>,
      excelname: "Usuario",
      minWidth: "150px",
      width: 20,
      selector: (row) => `${row.username}`,
      sortable: true,
      center: true,
      omit: otrosUsuariosInfo === 0,
   },
   {
      minWidth: "80px",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true,
      selector: (row) => null,
      cell: (row) => <EditButton row={row} onEditRow={handleEditarProspecto} />,
   },
   {
      name: "",
      minWidth: "50px",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      center: false,
      selector: (row) => null,
      cell: (row) => (
         <BrokersMenu
            size="small"
            row={row}
            onDeleteRow={handleEliminarProspecto}
         />
      ),
   },
];
