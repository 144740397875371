import React from 'react';
import { Row, Col } from 'reactstrap';
import { Btn } from '../../AbstractElements';

const AvailabilityColumns = ({ handleEditarDpto }) => {
   const columns = [
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Dpto</div>,
         minWidth: '20px',
         selector: (row) => `${row.depto}`,
         cell: (row) => (
            <div style={{ width: '100%', textAlign: 'center' }}>{row.depto}</div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Piso</div>,
         minWidth: '20px',
         selector: (row) => `${row.piso}`,
         cell: (row) => (
            <div style={{ width: '100%', textAlign: 'center' }}>{row.piso}</div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Tipo</div>,
         minWidth: '20px',
         selector: (row) => `${row.tipo}`,
         cell: (row) => (
            <div style={{ width: '100%', textAlign: 'center' }}>{row.tipo}</div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Interior</div>,
         minWidth: '20px',
         selector: (row) => `${row.minterior}`,
         cell: (row) => (
            <div style={{ width: '100%', textAlign: 'center' }}>{row.minterior}</div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Terraza</div>,
         minWidth: '20px',
         selector: (row) => `${row.mterraza}`,
         cell: (row) => (
            <div style={{ width: '100%', textAlign: 'center' }}>{row.mterraza}</div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Interesados</div>,
         minWidth: '50px',
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Precio</div>,
         minWidth: '50px',
         selector: (row) => `${row.precio}`,
         cell: (row) => (
            <div style={{ width: '100%', textAlign: 'center' }}>{row.precio}</div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Estado</div>,
         minWidth: '20px',
         selector: (row) => `${row.estado}`,
         cell: (row) => {
            let badgeClass = '';
            let statusText = '';

            switch (row.estado) {
               case 'D':
                  statusText = 'Disponible';
                  badgeClass = 'badge-success';
                  break;
               case 'V':
                  statusText = 'Vendido';
                  badgeClass = 'badge-danger';
                  break;
               case 'A':
                  statusText = 'Apartado';
                  badgeClass = 'badge-info';
                  break;

               default:
                  badgeClass = 'badge-light-light';
                  statusText = 'Otro';
            }

            return (
               <div style={{ width: '100%', textAlign: 'center' }}>
                  <span className={`badge ${badgeClass}`}>{statusText}</span>
               </div>
            );
         },
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Preventa</div>,
         minWidth: '50px',
         selector: (row) => `${row.preventa}`,
         cell: (row) => (
            <div style={{ width: '100%', textAlign: 'center' }}>
               {row.preventa ? 'Si' : 'No'}
            </div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Ficha técnica</div>,
         minWidth: '140px',
         button: true,
         ignoreRowClick: true,
         allowOverflow: true,
         center: false,
         cell: (row) => (
            <Row className="justify-content-center">
               <Col
                  xl="12"
                  md="12"
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     padding: '0px',
                  }}>
                  <Btn
                     attrBtn={{
                        style: {
                           width: '100px',
                           fontSize: '14px',
                        },
                        color: 'secondary',
                        className: 'btn btn-xs',
                        type: 'button',
                     }}>
                     <i className="fa fa-cloud-download">
                        {' '}
                        <span style={{ fontFamily: 'Rubik, sans-serif' }}>Descargar</span>
                     </i>
                  </Btn>
               </Col>
            </Row>
         ),
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Acción</div>,
         minWidth: '120px',
         button: true,
         ignoreRowClick: true,
         allowOverflow: true,
         center: false,
         cell: (row) => (
            <Row className="justify-content-center">
               <Col
                  xl="12"
                  md="12"
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     padding: '0 2px',
                  }}>
                  <Btn
                     attrBtn={{
                        style: {
                           width: '75px',
                           fontSize: '14px',
                        },
                        color: 'info',
                        className: 'btn btn-xs',
                        type: 'button',
                        onClick: () => {
                           handleEditarDpto(row);
                        },
                     }}>
                     <i className="fa fa-edit">
                        {' '}
                        <span style={{ fontFamily: 'Rubik, sans-serif' }}>Editar</span>
                     </i>
                  </Btn>
               </Col>
            </Row>
         ),
      },
   ];

   return columns;
};

export default AvailabilityColumns;
