import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
import useRecovery from '../../Hooks/recovery/useRecovery';

const RecuperacionPage = () => {
   const {
      recuperacion,
      modifiedIndex,
      setModifiedIndex,
      handleEditarRecuperacionAsunto,
      handleInputChange,
   } = useRecovery();

   return (
      <Fragment>
         <Breadcrumbs parent="Gestión" title="Recuperación" mainTitle="Recuperación" />
         <Container fluid={true}>
            <Row>
               <Col xl="6" className="box-col-6">
                  <Card>
                     <HeaderCard title="Asuntos - Correos de recuperación" />
                     <CardBody>
                        {recuperacion.map((rc, index) => (
                           <FormGroup className="row" key={index}>
                              <Col md="12">
                                 <Label className="col-form-label text-start fw-semibold">
                                    Recuperación {index + 1}:
                                 </Label>
                              </Col>
                              <Col xs="10" md="10">
                                 <Input
                                    value={rc.asuntoCorreo}
                                    id={index}
                                    type="textarea"
                                    className="form-control"
                                    rows="2"
                                    onChange={(e) => handleInputChange(e, index)}
                                 />
                              </Col>
                              <Col xs="2" md="2">
                                 {modifiedIndex === index && (
                                    <Btn
                                       attrBtn={{
                                          color: 'success',
                                          style: {
                                             padding: '5px 10px',
                                             marginTop: '3px',
                                             marginLeft: '10px',
                                          },
                                          onClick: () => {
                                             handleEditarRecuperacionAsunto();
                                             setModifiedIndex(null);
                                          },
                                       }}>
                                       <i className="fa fa-check"></i>
                                    </Btn>
                                 )}
                              </Col>
                           </FormGroup>
                        ))}
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </Container>
      </Fragment>
   );
};

export default RecuperacionPage;
