import React, {useState} from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import ProspectEditForm from "../Forms/ProspectEditForm";
import ProspectZapierForm from "../Forms/ProspectZapierForm";
import BrokerEditForm from "../../../brokers/components/Forms/BrokerEditForm";
import Tracing from "../../../tracing/Tracing";

const ProspectTabs = ({
   toggle,
   reload,
   p_desarrollos,
   prospecto,
   fuentes,
   etapas,
}) => {
   const [activeTab, setActiveTab] = useState("1");

   const handleTabClick = (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
   };
   return (
      <div className="m-b-20 m-l-10 m-r-10" style={{marginTop: "-20px"}}>
         <Nav tabs justified pills>
            <NavItem>
               <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => {
                     handleTabClick("1");
                  }}
               >
                  Editar prospecto
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => {
                     handleTabClick("2");
                  }}
               >
                  Zapier
               </NavLink>
            </NavItem>
            <NavItem>
               <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => {
                     handleTabClick("3");
                  }}
               >
                  Seguimiento
               </NavLink>
            </NavItem>
         </Nav>
         <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
               {prospecto.broker === 1 ? (
                  <BrokerEditForm
                     reload={reload}
                     prospecto={prospecto}
                     fuentes={fuentes}
                     toggle={toggle}
                  />
               ) : (
                  <ProspectEditForm
                     reload={reload}
                     p_desarrollos={p_desarrollos}
                     prospecto={prospecto}
                     fuentes={fuentes}
                     etapas={etapas}
                     toggle={toggle}
                  />
               )}
            </TabPane>
            <TabPane tabId="2">
               <ProspectZapierForm prospect={prospecto} />
            </TabPane>
            <TabPane tabId="3">
               <Tracing prospect={prospecto} />
            </TabPane>
         </TabContent>
      </div>
   );
};

export default ProspectTabs;
