import { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Request from '../../api/httpClient';
import DesarrollosContext from '../../_helper/Desarrollos';

const request = new Request();

const useDevelopments = () => {
   const { desarrollos } = useContext(DesarrollosContext);
   const [asunto_desarrollo, setAsuntoDesarrollo] = useState('');
   const [cuerpo_desarrollo, setCuerpoDesarrollo] = useState('');
   const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
   const [loadingAction, setLoadingAction] = useState(false);

   const handleEliminarDesarrollo = async (row) => {
      const result = await Swal.fire({
         title: 'Está seguro de eliminar este desarrollo?',
         text: 'Se eliminará el desarrollo: ' + row.nomDesarrollo,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Eliminar',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      });

      if (result.isConfirmed) {
         const data = { id_desarrollo: row.IDdesarrollo };

         try {
            const response = await request.post('/desarrollos/desarrollo/delete', data);
            if (!response.error) {
               Swal.fire('Eliminado!', 'Este desarrollo ha sido eliminado', 'success');
            } else {
               throw new Error(response.message);
            }
         } catch (error) {
            toast.error(error.message, {
               position: toast.POSITION.BOTTOM_RIGHT,
               autoClose: 2000,
            });
         }
      }
   };

   const handleGetAction = async (row) => {
      setAsuntoDesarrollo('');
      setCuerpoDesarrollo('');
      setLoadingAction(true);

      const data = { id_desarrollo: row.IDdesarrollo };
      try {
         const response = await request.post('/desarrollos/desarrollo/action/get', data);
         if (!response.error) {
            setAsuntoDesarrollo(response[0]?.asunto || '');
            setCuerpoDesarrollo(response[0]?.cuerpo || '');
         } else {
            throw new Error(response.message);
         }
      } catch (error) {
         toast.error(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      } finally {
         setLoadingAction(false);
      }
   };

   const handleUpdateAction = async () => {
      const data = {
         id_desarrollo: desarrollos[selectedButtonIndex].IDdesarrollo,
         asunto: asunto_desarrollo,
         cuerpo: cuerpo_desarrollo,
      };
      try {
         const response = await request.post(
            '/desarrollos/desarrollo/action/update',
            data
         );
         if (!response.error) {
            toast.success('Se guardaron los cambios', {
               position: toast.POSITION.BOTTOM_RIGHT,
               autoClose: 2000,
            });
         } else {
            throw new Error(response.message);
         }
      } catch (error) {
         toast.error(error.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   return {
      desarrollos,
      asunto_desarrollo,
      cuerpo_desarrollo,
      loadingAction,
      selectedButtonIndex,
      setSelectedButtonIndex,
      setAsuntoDesarrollo,
      setCuerpoDesarrollo,
      handleEliminarDesarrollo,
      handleGetAction,
      handleUpdateAction,
   };
};

export default useDevelopments;
