import React from "react";
import { Card, CardBody } from "reactstrap";
import { H4 } from "../../../AbstractElements";
import CountUp from "react-countup";
import SvgIcon from "../../Common/Component/SvgIcon";
const ProspectoTotalWidget = ({ data, mainClass }) => {
  return (
    <Card className={`small-widget ${mainClass ? mainClass : ""}`}>
      <CardBody className={data.color}>
        <span className="f-light">{data.nombre}</span>
        <div className="d-flex align-items-end gap-1">
          <H4>
            <CountUp
              suffix={data.suffix ? data.suffix : ""}
              prefix={data.prefix ? data.prefix : ""}
              duration={3}
              separator=","
              end={data.total}
            />
          </H4>
        </div>
        <div className="bg-gradient">
          <SvgIcon iconId={data.icon} className="stroke-icon svg-fill" />
        </div>
      </CardBody>
    </Card>
  );
};

export default ProspectoTotalWidget;
