import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {Container, Row, Col, Card, Label} from "reactstrap";
import {Breadcrumbs} from "../../AbstractElements";
import DataTableComponent from "../Tables/DataTable/DataTableComponent";
import useReports from "../../Hooks/reports/useReposts";
import ToolbarButtons from "../shared/Buttons/ToolbarButtons";

const Reports = () => {
   const {
      columns,
      isLoading,
      prospects,
      from,
      setFrom,
      to,
      setTo,
      fetchProspects,
      handleReport,
   } = useReports();

   return (
      <>
         <Breadcrumbs
            parent="Gestión"
            title="Reportes"
            mainTitle="Marketing - Sole Blu"
         />
         <Container fluid={true}>
            <Card style={{minHeight: `calc(90vh - 140px)`}}>
               <div className="m-20">
                  <Row>
                     <Col sm="3">
                        <Row>
                           <Label className="col-sm-6 col-form-label">
                              Desde:
                           </Label>
                           <Col sm="6">
                              <ReactDatePicker
                                 selected={from}
                                 onChange={(date) => {
                                    setFrom(date);
                                 }}
                                 className="form-control digits"
                                 dateFormat="dd/MM/yyyy"
                                 locale={es}
                              />
                           </Col>
                        </Row>
                     </Col>
                     <Col sm="3">
                        <Row>
                           <Label className="col-sm-6 col-form-label">
                              Hasta:
                           </Label>
                           <Col sm="6">
                              <ReactDatePicker
                                 className="form-control digits"
                                 selected={to}
                                 dateFormat="dd/MM/yyyy"
                                 locale={es}
                                 onChange={(date) => {
                                    setTo(date);
                                 }}
                              />
                           </Col>
                        </Row>
                     </Col>
                     <Col sm="6">
                        <ToolbarButtons
                           filename="Reportes"
                           onExcelClick={handleReport}
                           onFilterClick={fetchProspects}
                        />
                     </Col>
                     <Col sm="12" className="m-t-10 m-b-8">
                        <DataTableComponent
                           tableColumns={columns}
                           tableRows={prospects}
                           loadingData={isLoading}
                           pagination={true}
                           scrollHeight={"calc(84vh - 230px)"}
                           minHeight={`calc(82vh - 230px)`}
                        />
                     </Col>
                  </Row>
               </div>
            </Card>
         </Container>
      </>
   );
};

export default Reports;
