import "react-bootstrap-typeahead/css/Typeahead.css";
import {Button} from "reactstrap";
import {Modal, ModalBody, ModalHeader, ModalFooter} from "reactstrap";

const ModalContainer = ({
   modal,
   open,
   title,
   size,
   children,
   scroll,
   close,
}) => {
   return (
      <Modal
         isOpen={modal}
         toggle={open}
         size={size}
         scrollable={scroll}
         centered
      >
         <ModalHeader toggle={open}>{title}</ModalHeader>
         <ModalBody>{children}</ModalBody>
         {close && (
            <ModalFooter>
               <Button color="secondary" onClick={close}>
                  Cerrar
               </Button>
            </ModalFooter>
         )}
      </Modal>
   );
};

export default ModalContainer;
