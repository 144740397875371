import React from 'react';
import Routers from './Route';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
import DesarrollosProvider from './_helper/Desarrollos/DesarrollosProvider';
import EmbudosProvider from './_helper/Embudos/EmbudosProvider';
import MotivosProvider from './_helper/Motivos/MotivosProvider';
import pkg from '../package.json';
import CacheBuster from 'react-cache-buster';

const App = () => {
   // const isProduction = process.env.NODE_ENV === "production";
   const { version } = pkg;

   return (
      <CacheBuster
         currentVersion={version}
         isEnabled={true}
         isVerboseMode={true}
         metaFileDirectory={'.'}>
         <div className="App">
            <EmbudosProvider>
               <DesarrollosProvider>
                  <MotivosProvider>
                     <CustomizerProvider>
                        <AnimationThemeProvider>
                           <Routers />
                        </AnimationThemeProvider>
                     </CustomizerProvider>
                  </MotivosProvider>
               </DesarrollosProvider>
            </EmbudosProvider>
         </div>
      </CacheBuster>
   );
};

export default App;
