import React, {useState, useEffect, useMemo} from "react";
import moment from "moment";
import {Col, Form, FormGroup, Input} from "reactstrap";
import Request from "../../../api/httpClient";

const request = new Request();

const ProspectList = ({id}) => {
   const [prospectList, setProspectList] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [search, setSearch] = useState("");

   const fetchProspectList = async (id) => {
      setProspectList([]);
      setIsLoading(true);

      const data = {
         user_id: id,
      };

      const response = await request.post("/prospectos/by-user", data);

      if (response && !response.error) {
         if (response && !response.empty) {
            setProspectList(response);
         } else {
            setProspectList([]);
            setIsLoading(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }

      setIsLoading(false);
   };

   const handleChange = (event) => {
      setSearch(event.target.value);
   };

   const filteredProspectList = useMemo(() => {
      if (!search) return prospectList;

      return [...prospectList].filter((prospect) =>
         Object.values(prospect).some((value) =>
            String(value).toLowerCase().includes(search.toLowerCase())
         )
      );
   }, [prospectList, search]);

   useEffect(() => {
      fetchProspectList(id);
   }, [id]);

   return (
      <>
         {isLoading ? (
            <div className="text-center">
               <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
               </div>
            </div>
         ) : (
            <div>
               <Col sm="7">
                  <div className="product-search feature-products">
                     <Form>
                        <FormGroup className="m-0 form-group search-product">
                           <Input
                              className="form-control"
                              type="text"
                              placeholder="Buscar..."
                              value={search}
                              onChange={handleChange}
                           />
                           <i className="fa fa-search" />
                        </FormGroup>
                     </Form>
                  </div>
               </Col>
               <div className="table-responsive">
                  <table className="table ">
                     <thead>
                        <tr>
                           <th>Fecha de registro</th>
                           <th>Nombre</th>
                           <th>Correo</th>
                           <th>Teléfono</th>
                           <th>Desarrollo</th>
                           <th>Etapa</th>
                        </tr>
                     </thead>
                     <tbody>
                        {filteredProspectList.length > 0 ? (
                           filteredProspectList.map((prospect) => (
                              <tr key={prospect.id}>
                                 <td>
                                    {moment(prospect.register_date).format(
                                       "DD-MM-YYYY hh:mm A"
                                    )}
                                 </td>
                                 <td>{prospect.name}</td>
                                 <td>{prospect.email}</td>
                                 <td>{prospect.phone}</td>
                                 <td>{prospect.development_name}</td>
                                 <td>{prospect.funnel_name}</td>
                              </tr>
                           ))
                        ) : (
                           <tr>
                              <td colSpan="6" className="text-center">
                                 No tiene prospectos registrados
                              </td>
                           </tr>
                        )}
                     </tbody>
                  </table>
               </div>
            </div>
         )}
      </>
   );
};

export default ProspectList;
