import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import Request from '../../api/httpClient';
import DesarrollosContext from '../../_helper/Desarrollos';

const request = new Request();

const useAvailability = () => {
   const [searchTerm, setSearchTerm] = useState('');
   const [departamentos, setDepartamentos] = useState([]);
   const [departamento, setDepartamento] = useState(null);
   const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);
   const [modalEdit, setModalEdit] = useState(false);
   const [desarrolloSelec, setDesarrolloSelec] = useState({});
   const { desarrollos } = useContext(DesarrollosContext);

   const toggleEdit = () => setModalEdit(!modalEdit);

   const handleInputChange = (event) => {
      setSearchTerm(event.target.value);
   };

   const handleEditarDpto = (row) => {
      setDepartamento(row);
      toggleEdit();
   };

   const handleSelectChange = (event) => {
      const selectedId = event.target.value;
      const selectedDesarrollo = desarrollos.find(
         (desarrollo) => desarrollo.IDdesarrollo === parseInt(selectedId)
      );
      setDesarrolloSelec(selectedDesarrollo);
   };

   const getDisponibilidad = async () => {
      setDepartamentos([]);
      setLoadingDepartamentos(true);

      const data = {
         id_desarrollo: desarrolloSelec.IDdesarrollo || 0,
      };

      const response = await request.post('/disponibilidad/get/departamentos', data);
      if (response && !response.error) {
         if (response && !response.empty) {
            setDepartamentos(response);
         } else {
            setDepartamentos([]);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setLoadingDepartamentos(false);
   };

   const reload = (message) => {
      toast.success(message, {
         position: toast.POSITION.BOTTOM_RIGHT,
         autoClose: 2000,
      });
      getDisponibilidad();
   };

   useEffect(() => {
      if (desarrollos.length > 0) {
         setDesarrolloSelec(desarrollos[0]);
      }
   }, [desarrollos]);

   useEffect(() => {
      if (desarrollos.length > 0 && Object.keys(desarrolloSelec).length !== 0) {
         getDisponibilidad();
      }
   }, [desarrolloSelec]);

   const filteredData = departamentos.filter(
      (item) =>
         item.depto.toLowerCase().includes(searchTerm.toLowerCase()) ||
         item.estado.toLowerCase().includes(searchTerm.toLowerCase())
   );

   return {
      searchTerm,
      handleInputChange,
      filteredData,
      loadingDepartamentos,
      modalEdit,
      toggleEdit,
      departamento,
      handleEditarDpto,
      desarrolloSelec,
      handleSelectChange,
      desarrollos,
      reload,
   };
};

export default useAvailability;
