import React from "react";
import moment from "moment";
import EditButton from "../../Components/shared/Buttons/EditButton";

export const ZapierColumns = (handleEditarProspecto) => [
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Registro</div>,
      excelname: "Registro",
      minWidth: "120px",
      width: 15,
      selector: (row) => moment(row.Fecha),
      cell: (row) => `${moment(row.Fecha).format("DD-MM-YYYY")}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Nombre</div>,
      excelname: "Nombre",
      minWidth: "150px",
      width: 25,
      selector: (row) => `${row.Nombre}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Apellido</div>,
      excelname: "Apellido",
      minWidth: "120px",
      width: 15,
      selector: (row) => `${row.Apellido}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Teléfono</div>,
      excelname: "Teléfono",
      minWidth: "120px",
      width: 15,
      selector: (row) => `${row.WhatsApp}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Email</div>,
      excelname: "Apellido",
      minWidth: "120px",
      width: 15,
      selector: (row) => `${row.Email}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Asesor</div>,
      excelname: "Usuario",
      minWidth: "150px",
      width: 20,
      selector: (row) => `${row?.username || ""}`,
      sortable: true,
      center: true,
   },
   {
      minWidth: "80px",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true,
      selector: (row) => null,
      cell: (row) => <EditButton row={row} onEditRow={handleEditarProspecto} />,
   },
];
