import React, {Fragment, useState, useRef} from "react";
import {Form, FormGroup, Input, Col, Media} from "reactstrap";
import {Btn, H4, P} from "../../../AbstractElements";
import {Link} from "react-router-dom";
import Logo from "../../../assets/images/login/logo-login.png";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";
import {MD5} from "crypto-js";
import Request from "../../../api/httpClient";
import Swal from "sweetalert2";

const RegisterFrom = ({logoClassMain}) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const recaptchaRef = useRef(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const request = new Request();

  const generatePassword = () => {
    var cadena =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var password = "";
    for (var i = 0; i < 6; i++) {
      password += cadena.charAt(Math.floor(Math.random() * cadena.length));
    }
    return password;
  };

  const [contrasenna] = useState(generatePassword());

  const handleSubmit = async (event) => {
    event.preventDefault();

    const parts = fullName.split(" ");
    const recaptchaValue = recaptchaRef.current.getValue();
    const fullNameInput = event.target.elements.fullName;

    if (parts.length < 3) {
      fullNameInput.setCustomValidity(
        "Por favor, ingrese su nombre y sus dos apellidos."
      );
      fullNameInput.reportValidity();
      return;
    } else if (!recaptchaValue) {
      toast.error("Por favor, complete el captcha.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });

      return;
    } else {
      setIsSubmitDisabled(true);

      const sanitizedPassword = contrasenna.replace(/[^a-zA-Z0-9]/g, "");
      const reversedPassword = sanitizedPassword.split("").reverse().join("");
      const encryptedPassword = MD5(reversedPassword).toString();

      let data = {
        parentuser: 0,
        nombre: fullName,
        email: email,
        telefono: telefono,
        celular: "",
        tipoUsuario: "S",
        comision: "0",
        contrasenna: encryptedPassword,
        tipoAsesor: "Externo",
        tipoRegistro: 1,
        password: contrasenna,
        register_id: 0,
      };

      const response = await request.post("/usuarios/usuario/create", data);

      if (response && !response.error) {
        setIsSubmitDisabled(false);
        Swal.fire(
          "Correo enviado!",
          "Cuenta creada con éxito. Revise su correo para acceder a la plataforma.",
          "success"
        );
      } else {
        setIsSubmitDisabled(false);
        toast.error(response.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    }
  };

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
    event.target.setCustomValidity("");
  };

  return (
    <Fragment>
      <div className="login-card">
        <div className="login-main">
          <div
            className={`logo ${logoClassMain ? logoClassMain : ""}`}
            to={process.env.PUBLIC_URL}
          >
            <Media src={Logo} alt="" style={{width: "25%"}} />
          </div>
          <Form className="theme-form login-form" onSubmit={handleSubmit}>
            <H4 attrH4={{className: " text-light"}}>Crea tu cuenta</H4>
            <P>Ingrese sus datos personales</P>
            <FormGroup>
              <Input
                className="form-control"
                type="text"
                onChange={handleFullNameChange}
                required
                name="fullName"
                placeholder="Nombre completo"
              />
            </FormGroup>
            <FormGroup className="row">
              <Col xl="7">
                <Input
                  className="form-control"
                  type="email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                  required
                />
              </Col>
              <Col xl="5">
                <Input
                  className="form-control"
                  type="tel"
                  minLength={10}
                  onChange={(e) => {
                    const telefono = e.target.value.replace(/[^0-9]/g, "");
                    setTelefono(telefono);
                  }}
                  placeholder="Teléfono"
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup style={{display: "flex", justifyContent: "center"}}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />
            </FormGroup>

            <FormGroup>
              <Btn
                attrBtn={{
                  className: "d-block w-100",
                  color: "primary",
                  type: "submit",
                  disabled: isSubmitDisabled,
                }}
              >
                Crear cuenta
              </Btn>
            </FormGroup>
            <P attrPara={{className: "mb-0 text-center"}}>
              ¿Ya tienes una cuenta?
              <Link className="ms-2" to={`/login`}>
                Iniciar sesión
              </Link>
            </P>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterFrom;
