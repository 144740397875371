import {saveAs} from "file-saver";
import moment from "moment-timezone";
const ExcelJS = require("exceljs");

const useExcel = () => {
   const handleExport = (tables, filename) => {
      const workbook = new ExcelJS.Workbook();

      tables.forEach((table, index) => {
         const {tableColumns, tableRows} = table;

         const worksheet = workbook.addWorksheet(`${filename}_${index + 1}`);

         const headers = tableColumns.map(
            (column) => column.excelname || column.name
         );
         worksheet.addRow(headers);

         const columnHeaders = tableColumns.map((column) => ({
            header: column.excelname || column.name,
            key: column.excelname || column.name,
            width: column.width,
            style: {numFmt: column.numFmt},
         }));

         worksheet.columns = columnHeaders;

         tableColumns.forEach((column, index) => {
            worksheet.getCell(1, index + 1).font = {
               bold: true,
               size: 12,
               color: {argb: "FFFFFFFF"},
            };
            worksheet.getCell(1, index + 1).fill = {
               type: "pattern",
               pattern: "solid",
               fgColor: {argb: "16c7f9"},
            };
         });

         tableRows.forEach((row) => {
            const rowData = tableColumns.map((column) => {
               const value = column.selector(row);
               if (column.numFmt && column.numFmt !== "@") {
                  return Number(value);
               }
               return value;
            });
            worksheet.addRow(rowData);
         });

         worksheet.eachRow({includeEmpty: true}, (row, rowNumber) => {
            if (rowNumber > 1) {
               row.eachCell({includeEmpty: true}, (cell) => {
                  cell.border = {
                     top: {style: "thin", color: {argb: "FF000000"}},
                     left: {style: "thin", color: {argb: "FF000000"}},
                     bottom: {style: "thin", color: {argb: "FF000000"}},
                     right: {style: "thin", color: {argb: "FF000000"}},
                  };
               });
            } else {
               worksheet.getRow(1).eachCell({includeEmpty: true}, (cell) => {
                  cell.border = {
                     bottom: {style: "thin", color: {argb: "FF000000"}},
                  };
               });
            }
         });
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
         const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
         });
         saveAs(blob, filename + ".xlsx");
      });
   };

   const handleExport2 = (data, filename) => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(filename);
      worksheet.views = [{zoomScale: 80}];

      if (data.length > 0) {
         const headers = Object.keys(data[0]);
         worksheet.addRow(headers).eachCell((cell, number) => {
            cell.font = {
               bold: true,
               size: 12,
               color: {argb: "FFFFFFFF"},
            };
            if (
               headers[number - 1] === "Correo" ||
               headers[number - 1] === "Comentarios"
            ) {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: {argb: "FF0000"},
               };
            } else {
               cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: {argb: "16c7f9"},
               };
            }
            cell.border = {
               bottom: {style: "thin", color: {argb: "FF000000"}},
            };
            cell.alignment = {wrapText: true};
         });

         const columns = headers.map((header) => ({
            header: header,
            key: header,
            width: 26,
         }));
         worksheet.columns = columns;

         data.forEach((item) => {
            const values = headers.map((header) => {
               if (header === "Registro" && item[header]) {
                  return moment(item[header]).format("DD-MM-YYYY HH:mm:ss");
               } else {
                  return item[header];
               }
            });
            const row = worksheet.addRow(values);
            row.eachCell((cell, number) => {
               cell.border = {
                  top: {style: "thin", color: {argb: "FF000000"}},
                  left: {style: "thin", color: {argb: "FF000000"}},
                  bottom: {style: "thin", color: {argb: "FF000000"}},
                  right: {style: "thin", color: {argb: "FF000000"}},
               };
               cell.alignment = {
                  wrapText: true,
                  vertical: "middle",
                  horizontal: "left",
               };
            });
         });
      } else {
         return [];
      }

      workbook.xlsx.writeBuffer().then((buffer) => {
         const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
         });
         saveAs(blob, `${filename}.xlsx`);
      });
   };

   return {handleExport, handleExport2};
};

export default useExcel;
