import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Request from '../../api/httpClient';

const useProspectEditForm = ({
   toggle,
   reload,
   p_desarrollos,
   prospecto,
   fuentes,
   etapas,
}) => {
   const userId = localStorage.getItem('user_id') || 0;
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const [nombre, setNombre] = useState(prospecto.client);
   const [email, setEmail] = useState(prospecto.emailclient);
   const [telefono, setTelefono] = useState(prospecto.phoneclient);
   const [ciudad, setCiudad] = useState(prospecto.city);
   const [comentarios, setComentarios] = useState(prospecto.notes);

   const desarrollos = p_desarrollos.map((item) => {
      return {
         label: item.nomDesarrollo,
         value: item.IDdesarrollo,
      };
   });
   const [desarrollo, setDesarrollo] = useState(
      desarrollos.find((item) => item.value === prospecto.desarrolloID)
   );

   const calificaciones = [
      { value: 1, label: 1 },
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
   ];
   const [calificacion, setCalificacion] = useState(
      calificaciones.find((item) => item.value === prospecto.calificacion)
   );

   const [fuente, setFuente] = useState(
      fuentes.find((item) => item.value === prospecto.origin)
   );
   const [departamentos, setDepartamentos] = useState([]);
   const [departamentosSelec, setDepartamentosSelec] = useState(null);
   const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);
   const [etapa, setEtapa] = useState(
      etapas.find((item) => item.value === prospecto.funnelId)
   );

   const posponerOpciones = [
      { value: 'F&F', label: 'F&F' },
      { value: '1 Mes', label: '1 Mes' },
      { value: '2 Meses', label: '2 Meses' },
      { value: '3 Meses', label: '3 Meses' },
      { value: 'Próximo año', label: 'Próximo año' },
   ];
   const [posponer, setPosponer] = useState(
      posponerOpciones.find((item) => item.value === prospecto.posponer) || null
   );

   const [emailRecuperacion1_fecha] = useState(prospecto.emailRecuperacion1_fecha);
   const [emailRecuperacion2_fecha] = useState(prospecto.emailRecuperacion2_fecha);
   const [emailRecuperacion3_fecha] = useState(prospecto.emailRecuperacion3_fecha);

   const request = new Request();

   useEffect(() => {
      if (desarrollo) {
         getDepartamentos();
      }
   }, [desarrollo]);

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitDisabled(true);

      const editionDate = new Date();

      const data = {
         id_prospecto: prospecto.pkclient,
         cliente: nombre,
         email: email,
         telefono: telefono,
         ciudad: ciudad,
         budget: '',
         comentarios: comentarios,
         interes: departamentosSelec ? departamentosSelec.label : '',
         origen: fuente.value,
         origen_otro: '',
         broker: false,
         calificacion: calificacion.value,
         id_desarrollo: desarrollo?.value || prospecto.desarrolloID,
         id_embudo: etapa.value,
         posponer: posponer && posponer.value,
         advisorId: prospecto.pkuser,
         currentPhone: prospecto.phoneclient,
         currentEmail: prospecto.emailclient,
         edit_date: editionDate,
         editor_id: userId,
      };

      const response = await request.post('/prospectos/prospecto/update', data);

      if (response && !response.error) {
         toggle();
         setPosponer(null);
         reload(
            true,
            'Prospecto editado con éxito',
            desarrollo?.value || prospecto.desarrolloID
         );
      } else {
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
      setIsSubmitDisabled(false);
   };

   const getDepartamentos = async () => {
      setLoadingDepartamentos(true);
      const data = { id_desarrollo: desarrollo.value };
      const response = await request.post('/prospectos/get/departamentos', data);

      if (response && !response.error && !response.empty) {
         const dptos = response.map((dpto) => {
            return {
               value: dpto.pkdepto,
               label: dpto.depto,
            };
         });
         setDepartamentos(dptos);
         setDepartamentosSelec(
            dptos.find((item) => item.label === prospecto.interesting)
         );
      } else {
         console.error(response.message || 'Error al cargar departamentos');
         setDepartamentos([]);
      }
      setLoadingDepartamentos(false);
   };

   return {
      isSubmitDisabled,
      setIsSubmitDisabled,
      toggle,
      nombre,
      email,
      telefono,
      ciudad,
      comentarios,
      desarrollo,
      desarrollos,
      calificacion,
      calificaciones,
      fuente,
      fuentes,
      departamentos,
      departamentosSelec,
      loadingDepartamentos,
      etapa,
      etapas,
      posponer,
      posponerOpciones,
      setNombre,
      setEmail,
      setTelefono,
      setCiudad,
      setComentarios,
      setDesarrollo,
      setCalificacion,
      setFuente,
      setDepartamentosSelec,
      setEtapa,
      setPosponer,
      handleSubmit,
      emailRecuperacion1_fecha,
      emailRecuperacion2_fecha,
      emailRecuperacion3_fecha,
   };
};

export default useProspectEditForm;
