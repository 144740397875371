import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {Form, FormGroup, Input, Label, Row} from "reactstrap";
import CommonModal from "../UiKits/Modals/common/modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import Select from "react-select";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {toast} from "react-toastify";
import {MD5} from "crypto-js";

const request = new Request();

const UsuarioForm = ({modal, title, toggle, size, reload, rol}) => {
   const userId = localStorage.getItem("user_id") || 0;
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const [nombre, setNombre] = useState("");
   const [email, setEmail] = useState("");
   const [telefono, setTelefono] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [internalAdvisors, setInternalAdvisors] = useState([]);
   const [selectedInternalAdvisor, setSelectedInternalAdvisor] = useState("");
   const userRole = localStorage.getItem("Role");

   const tipoUsuarios = [
      {value: "A", label: "Administrador", type: "Interno"},
      {value: "V", label: "Asesor Interno", type: "Interno"},
      {value: "S", label: "Asesor Externo", type: "Externo"},
      {value: "M", label: "Agencia", type: "Interno"},
   ];

   const [tipoUsuario, setTipoUsuario] = useState(
      tipoUsuarios.find((user) => user.value === rol)
   );

   const comisiones = [
      {value: 2, label: 2},
      {value: 3, label: 3},
      {value: 4, label: 4},
      {value: 5, label: 5},
   ];

   const [comision, setComision] = useState(comisiones[0]);

   const generarPassword = () => {
      var cadena =
         "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var password = "";
      for (var i = 0; i < 6; i++) {
         password += cadena.charAt(Math.floor(Math.random() * cadena.length));
      }
      return password;
   };

   const [contrasenna, setContrasenna] = useState(generarPassword());

   const LimpiarDatos = () => {
      setNombre("");
      setEmail("");
      setTelefono("");
   };

   const fetchInternalAdvisors = async () => {
      setInternalAdvisors([]);
      setIsLoading(true);

      const response = await request.get("/usuarios/internal-advisors");

      if (response && !response.error) {
         if (response && !response.empty) {
            setInternalAdvisors(response);
         } else {
            setInternalAdvisors([]);
            setIsLoading(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }

      setIsLoading(false);
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitDisabled(true);

      const sanitizedPassword = contrasenna.replace(/[^a-zA-Z0-9]/g, "");
      const reversedPassword = sanitizedPassword.split("").reverse().join("");
      const encryptedPassword = MD5(reversedPassword).toString();

      const advisor = internalAdvisors.find(
         (a) => a.value === selectedInternalAdvisor
      );

      let data = {
         parentuser: advisor?.id || userId,
         nombre: nombre,
         email: email,
         telefono: telefono,
         celular: telefono,
         tipoUsuario: tipoUsuario.value,
         comision: comision.value,
         contrasenna: encryptedPassword,
         tipoAsesor: tipoUsuario?.type,
         tipoRegistro: 0,
         password: contrasenna,
         register_id: userId,
      };

      const response = await request.post("/usuarios/usuario/create", data);

      if (response && !response.error) {
         toggle();
         setIsSubmitDisabled(false);
         reload("Usuario creado con éxito ");
      } else {
         setIsSubmitDisabled(false);
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   useEffect(() => {
      if (modal) {
         LimpiarDatos();
      }
   }, [modal]);

   useEffect(() => {
      fetchInternalAdvisors();
   }, []);

   return (
      <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
         <Modal isOpen={modal} toggle={toggle} size={size} centered>
            <Form onSubmit={handleSubmit}>
               <span className="badge badge-primary m-5">Registro interno</span>
               <ModalHeader toggle={toggle}>{title}</ModalHeader>
               <ModalBody style={{paddingTop: "2px", paddingBottom: "2px"}}>
                  <Row>
                     <Col sm="12" md="12">
                        <div
                           className="project-box"
                           style={{paddingBottom: "5px"}}
                        >
                           <FormGroup className="row">
                              <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Nombre:
                                 </Label>
                                 <Input
                                    id="nombre"
                                    type="text"
                                    onChange={(e) => {
                                       setNombre(e.target.value);
                                    }}
                                    required
                                 />
                              </Col>
                              <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Email:
                                 </Label>
                                 <Input
                                    id="email"
                                    type="email"
                                    onChange={(e) => {
                                       setEmail(e.target.value);
                                    }}
                                    required
                                 />
                              </Col>

                              <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Teléfono:
                                 </Label>
                                 <Input
                                    id="telefono"
                                    type="tel"
                                    value={telefono}
                                    onChange={(e) => {
                                       const telefono = e.target.value.replace(
                                          /[^0-9]/g,
                                          ""
                                       );
                                       setTelefono(telefono);
                                    }}
                                 />
                              </Col>
                              {/* <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Celular:
                                 </Label>
                                 <Input
                                    id="celular"
                                    type="tel"
                                    onChange={(e) => {
                                       setCelular(e.target.value);
                                    }}
                                 />
                              </Col> */}
                              <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Comisión:
                                 </Label>
                                 <Select
                                    value={comision}
                                    options={comisiones}
                                    placeholder={"Seleccione..."}
                                    onChange={(comision) =>
                                       setComision(comision)
                                    }
                                    required
                                 />
                              </Col>
                              <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Contraseña:
                                 </Label>
                                 <Input
                                    id="contrasenna"
                                    value={contrasenna}
                                    type="text"
                                    onChange={(e) => {
                                       setContrasenna(e.target.value);
                                    }}
                                    minLength="6"
                                    required
                                 />
                              </Col>

                              <Col xl="6" sm="12">
                                 <Label className="col-form-label text-start">
                                    Tipo de usuario:
                                 </Label>
                                 <Select
                                    value={tipoUsuario}
                                    options={tipoUsuarios}
                                    placeholder={"Seleccione..."}
                                    onChange={(tipoUsuario) =>
                                       setTipoUsuario(tipoUsuario)
                                    }
                                    required
                                 />
                              </Col>
                              {tipoUsuario.value === "S" &&
                                 userRole === "A" && (
                                    <Col sm="12">
                                       <Label className="col-form-label text-start">
                                          Asesor interno:
                                       </Label>
                                       <Select
                                          required
                                          options={internalAdvisors}
                                          placeholder={
                                             "Seleccionar asesor interno"
                                          }
                                          onChange={(e) =>
                                             setSelectedInternalAdvisor(
                                                e?.value
                                             )
                                          }
                                          isLoading={isLoading}
                                          styles={{
                                             menu: (provided) => ({
                                                ...provided,
                                                zIndex: 3,
                                             }),
                                          }}
                                       />
                                    </Col>
                                 )}
                           </FormGroup>
                        </div>
                     </Col>
                  </Row>
               </ModalBody>
               <ModalFooter>
                  <Btn attrBtn={{color: "secondary", onClick: toggle}}>
                     Cancelar
                  </Btn>
                  <Btn
                     attrBtn={{
                        type: "submit",
                        color: "primary",
                        disabled: isSubmitDisabled,
                     }}
                  >
                     {isSubmitDisabled ? "Guardando..." : "Guardar"}
                  </Btn>
               </ModalFooter>
            </Form>
         </Modal>
      </CommonModal>
   );
};

export default UsuarioForm;
