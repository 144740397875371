import {Button} from "reactstrap";
import EditIcon from "@mui/icons-material/Edit";

const EditButton = ({row, onEditRow}) => {
   const editRow = () => {
      if (onEditRow) {
         onEditRow(row);
      }
   };
   return (
      <Button
         color="primary"
         onClick={editRow}
         style={{
            width: 50,
            paddingRight: 2,
            paddingLeft: 2,
            paddingTop: 4,
            paddingBottom: 4,
            fontSize: 4,
         }}
      >
         <EditIcon />
      </Button>
   );
};

export default EditButton;
