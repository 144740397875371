import React from "react";
import {Col, Media, Input, Label} from "reactstrap";
const ProspectRecoveryEmail = (props) => {
   const {emailRecuperacion, setEmailRecuperacion, loadingProspectos} = props;

   const options = [
      {value: 0, label: "0"},
      {value: 1, label: "1"},
      {value: 2, label: "2"},
      {value: 3, label: "3"},
      {value: -1, label: "Cualquiera"},
   ];

   return (
      <Col>
         {!loadingProspectos && (
            <Media className="d-flex align-items-center ">
               <Label className="col-form-label text-start">
                  Correos de recuperación: &nbsp;
               </Label>
               <Media>
                  <div className="m-checkbox-inline custom-radio-ml d-flex">
                     <div className="radio radio-primary">
                        {options.map((option, index) => (
                           <div key={index} className="radio radio-primary">
                              <Input
                                 id={`radioinline${option.value}`}
                                 type="radio"
                                 name="radio"
                                 checked={emailRecuperacion === option.value}
                                 onChange={() =>
                                    setEmailRecuperacion(option.value)
                                 }
                              />
                              <Label
                                 className="mb-0"
                                 for={`radioinline${option.value}`}
                              >
                                 {option.label}
                              </Label>
                           </div>
                        ))}
                     </div>
                  </div>
               </Media>
            </Media>
         )}
      </Col>
   );
};

export default ProspectRecoveryEmail;
