import React, {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import moment from "moment";
import {Container, Row, Col, Card, Form, FormGroup, Input} from "reactstrap";
import Swal from "sweetalert2";
import {Breadcrumbs} from "../../AbstractElements";
import Request from "../../api/httpClient";
import DataTableComponent from "../Tables/DataTable/DataTableComponent";
import DesarrollosContext from "../../_helper/Desarrollos";
import EmbudosContext from "../../_helper/Embudos";
import MotivosContext from "../../_helper/Motivos";
import ProspectoFormClone from "./ProspectoFormClone";
import ProspectFilters from "./components/ProspectFilters/ProspectFilters";
import ToolbarButtons from "../shared/Buttons/ToolbarButtons";
import {prospectsColumns} from "../../Data/Table/ProspectsColumns";
import ProspectEditModal from "./components/Modals/ProspectEditModal";
import ProspectCreateModal from "./components/Modals/ProspectCreateModal";
import ModalContainer from "../shared/ModalContainer";
import FilterRangeDate from "../shared/Filters/FilterRangeDate";
const request = new Request();

const ProspectosPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const {embudos, loadingEmbudos} = useContext(EmbudosContext);
  const {motivos} = useContext(MotivosContext);
  const [prospectos, setProspectos] = useState([]);
  const {desarrollos} = useContext(DesarrollosContext);
  const [prospecto, setProspecto] = useState(null);
  const [loadingProspectos, setLoadingProspectos] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);
  const toggleCreate = () => setModalCreate(!modalCreate);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [modalClone, setModalClone] = useState(false);
  const toggleClone = () => setModalClone(!modalClone);
  const [desarrolloSelec, setDesarrolloSelec] = useState({});
  const [statusList, setStatusList] = useState({});
  const [reasonsList, setReasonsList] = useState({});
  const [canales, setCanales] = useState([]);
  const [loadingCanales, setLoadingCanales] = useState(false);
  const [otrosUsuariosInfo, setOtrosUsauriosInfo] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(!showModal);
  const [from, setFrom] = useState(moment().clone().startOf("month").toDate());
  const [to, setTo] = useState(moment().clone().endOf("month").toDate());

  const etapasFilter = embudos.map((item) => {
    return {
      id: item.funnelId,
      label: item.funnelName,
      value: item.funnelName,
    };
  });

  const [etapaFilter, setEtapaFilter] = useState("");
  const [emailRecuperacion, setEmailRecuperacion] = useState(-1);
  const isBroker = false;

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = prospectos.filter((item) => {
    const baseCondition =
      (item.client.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.emailclient?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.phoneclient?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.username?.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (etapaFilter === "" ||
        item.funnelName.toLowerCase() === etapaFilter.toLowerCase());

    let emailCondition = false;

    switch (emailRecuperacion) {
      case 0:
        emailCondition =
          item.emailRecuperacion1_fecha === null &&
          item.emailRecuperacion2_fecha === null &&
          item.emailRecuperacion3_fecha === null;
        break;
      case 1:
        emailCondition =
          item.emailRecuperacion1_fecha !== null &&
          item.emailRecuperacion2_fecha === null &&
          item.emailRecuperacion3_fecha === null;
        break;
      case 2:
        emailCondition =
          item.emailRecuperacion1_fecha !== null &&
          item.emailRecuperacion2_fecha !== null &&
          item.emailRecuperacion3_fecha === null;
        break;
      case 3:
        emailCondition =
          item.emailRecuperacion1_fecha !== null &&
          item.emailRecuperacion2_fecha !== null &&
          item.emailRecuperacion3_fecha !== null;
        break;
      case -1:
        emailCondition = true;

        break;
      default:
        emailCondition = false;
    }

    // const brokerCondition = item.broker !== 1;
    const brokerCondition = !isBroker || (isBroker && item.broker === 1);

    return baseCondition && emailCondition && brokerCondition;
  });

  const getProspectos = async () => {
    setProspectos([]);
    setLoadingProspectos(true);
    const data = {
      id_usuario: otrosUsuariosInfo ? 0 : localStorage.getItem("user_id") || 0,
      id_desarrollo: desarrolloSelec.IDdesarrollo || 0,
    };

    const response = await request.post("/prospectos", data);

    if (response && !response.error) {
      if (response && !response.empty) {
        setProspectos(response);
      } else {
        setProspectos([]);
        setLoadingProspectos(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingProspectos(false);
  };

  const fetchReport = async () => {
    const stage = etapasFilter.find((item) => item.value === etapaFilter);

    const data = {
      id_usuario: otrosUsuariosInfo ? 0 : localStorage.getItem("user_id") || 0,
      id_desarrollo: desarrolloSelec.IDdesarrollo || 0,
      id_embudo: stage?.id || 0,
      from: moment(from).format("YYYY-MM-DD 00:00:00"),
      to: moment(to).format("YYYY-MM-DD 23:59:59"),
    };

    const response = await request.post("/prospectos/report", data);
    return response;
  };

  const getCanales = async () => {
    setCanales([]);
    setLoadingCanales(true);
    const response = await request.get("/canales/get/all");
    if (response && !response.error) {
      if (response && !response.empty) {
        const fuentes = response.map((item) => {
          return {
            label: item.channelName,
            value: item.channelName,
          };
        });
        setCanales(fuentes);
      } else {
        setCanales([]);
        setLoadingCanales(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingCanales(false);
  };

  const handleEditarProspecto = (row) => {
    setProspecto(row);
    toggleEdit();
  };

  const handleCambiarStatusProspecto = (row) => {
    Swal.fire({
      title: "Cambio de etapa",
      text: "Se cambiará la etapa de: " + row.client,
      icon: "warning",
      input: "select",
      inputOptions: statusList,
      inputValue: row.funnelId,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cambiar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Razón del cambio",
          text: "Puede dejar un comentario sobre el cambio de estado",
          icon: "warning",
          input: "select",
          inputValue: 0,
          inputOptions: reasonsList,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          reverseButtons: true,
        }).then(async (reasonResult) => {
          if (reasonResult.isConfirmed) {
            if (reasonResult.value === "otros") {
              Swal.fire({
                title: "Ingrese su razón",
                input: "textarea",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
                reverseButtons: true,
              }).then(async (textResult) => {
                if (textResult.isConfirmed) {
                  let data = {
                    id_prospecto: row.pkclient,
                    nombre_prospecto: row.client,
                    email_prospecto: row.emailclient,
                    funnelId: result.value,
                    reason: textResult.value,
                    id_usuario: localStorage.getItem("user_id") || 0,
                    id_desarrollo: desarrolloSelec.IDdesarrollo || 0,
                    actividad: JSON.stringify({
                      value:
                        "Se realizo un cambio de embudo de <strong>" +
                        row.funnelName +
                        "</strong> a <strong>" +
                        statusList[result.value] +
                        "</strong>.",
                      details: {
                        from: row.funnelName,
                        to: statusList[result.value],
                      },
                    }),
                  };

                  const response = await request.post(
                    "/prospectos/prospecto/status",
                    data
                  );

                  if (response && !response.error) {
                    if (result.value === "7") {
                      const leadsResponse = await request.get("/kommo/leads");
                      const leads = Array.isArray(
                        leadsResponse.data?._embedded?.leads
                      )
                        ? leadsResponse.data._embedded.leads
                        : [];

                      const existingLead = leads.find((lead) => {
                        const emailField = lead.custom_fields_values?.find(
                          (field) => field.field_name === "Correo"
                        );
                        const phoneField = lead.custom_fields_values?.find(
                          (field) => field.field_name === "Teléfono"
                        );
                        const developmentField =
                          lead.custom_fields_values?.find(
                            (field) => field.field_name === "Desarrollo"
                          );

                        const leadEmail =
                          emailField?.values?.[0]?.value || null;
                        const leadPhone =
                          phoneField?.values?.[0]?.value || null;
                        const leadDevelopment =
                          developmentField?.values?.[0]?.value || null;

                        return (
                          leadDevelopment === row.nomDesarrollo &&
                          (leadEmail === row.emailclient ||
                            leadPhone === row.phoneclient)
                        );
                      });

                      if (!existingLead) {
                        const kommoData = {
                          leadId: row.pkclient,
                          name: row.client,
                          email: row.emailclient,
                          phone: row.phoneclient,
                          budget: 0,
                          development: row.nomDesarrollo,
                        };

                        const kommoResponse = await request.post(
                          "/kommo/leads",
                          kommoData
                        );

                        if (kommoResponse && kommoResponse.error) {
                          toast.error("Error al enviar los datos a Kommo", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 2000,
                          });
                        }

                        if (kommoResponse && !kommoResponse.error) {
                          toast.success("Prospecto enviado a Kommo", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 2000,
                          });
                        }
                      } else {
                        console.log("El lead ya existe en Kommo.");
                      }
                    }
                    Swal.fire(
                      "Operación completada!",
                      "Se cambió la etapa del prospecto",
                      "success"
                    );
                    reload(true);
                  } else {
                    toast.error(response.message, {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      autoClose: 2000,
                    });
                  }
                }
              });
            } else {
              let data = {
                id_prospecto: row.pkclient,
                nombre_prospecto: row.client,
                email_prospecto: row.emailclient,
                funnelId: result.value,
                reason:
                  reasonResult.value === "0"
                    ? ""
                    : reasonsList[reasonResult.value],
                id_usuario: localStorage.getItem("user_id") || 0,
                id_desarrollo: desarrolloSelec.IDdesarrollo || 0,
                actividad: JSON.stringify({
                  value:
                    "Se realizo un cambio de embudo de <strong>" +
                    row.funnelName +
                    "</strong> a <strong>" +
                    statusList[result.value] +
                    "</strong>.",
                  details: {
                    from: row.funnelName,
                    to: statusList[result.value],
                  },
                }),
              };

              const response = await request.post(
                "/prospectos/prospecto/status",
                data
              );
              if (response && !response.error) {
                if (result.value === "7") {
                  const leadsResponse = await request.get("/kommo/leads");
                  const leads = Array.isArray(
                    leadsResponse.data?._embedded?.leads
                  )
                    ? leadsResponse.data._embedded.leads
                    : [];

                  const existingLead = leads.find((lead) => {
                    const emailField = lead.custom_fields_values?.find(
                      (field) => field.field_name === "Correo"
                    );
                    const phoneField = lead.custom_fields_values?.find(
                      (field) => field.field_name === "Teléfono"
                    );
                    const developmentField = lead.custom_fields_values?.find(
                      (field) => field.field_name === "Desarrollo"
                    );

                    const leadEmail = emailField?.values?.[0]?.value || null;
                    const leadPhone = phoneField?.values?.[0]?.value || null;
                    const leadDevelopment =
                      developmentField?.values?.[0]?.value || null;

                    return (
                      leadDevelopment === row.nomDesarrollo &&
                      (leadEmail === row.emailclient ||
                        leadPhone === row.phoneclient)
                    );
                  });

                  if (!existingLead) {
                    const kommoData = {
                      leadId: row.pkclient,
                      name: row.client,
                      email: row.emailclient,
                      phone: row.phoneclient,
                      budget: 0,
                      development: row.nomDesarrollo,
                    };

                    const kommoResponse = await request.post(
                      "/kommo/leads",
                      kommoData
                    );

                    if (kommoResponse && kommoResponse.error) {
                      toast.error("Error al enviar los datos a Kommo", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                      });
                    }

                    if (kommoResponse && !kommoResponse.error) {
                      toast.success("Prospecto enviado a Kommo", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                      });
                    }
                  } else {
                    console.log("El lead ya existe en Kommo.");
                  }
                }
                Swal.fire(
                  "Operación completada!",
                  "Se cambió la etapa del prospecto",
                  "success"
                );
                reload(true);
              } else {
                toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
                });
              }
            }
          }
        });
      }
    });
  };

  const handleClonarProspecto = (row) => {
    setProspecto(row);
    toggleClone();
  };

  const handleEliminarProspecto = (row) => {
    Swal.fire({
      title: "Está seguro de eliminar este prospecto?",
      text: "Se eliminará el prospecto: " + row.client,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = {
          id_prospecto: row.pkclient,
        };

        const response = await request.post(
          "/prospectos/prospecto/delete",
          data
        );
        if (response && !response.error) {
          Swal.fire(
            "Eliminado!",
            "Este prospecto ha sido eliminado",
            "success"
          );
          getProspectos();
        } else {
          toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      }
    });
  };

  const reload = (refresh, message, id_desarrollo) => {
    if (desarrollos.length > 0) {
      const desarrolloSeleccionado = desarrollos.find(
        (desarrollo) => desarrollo.IDdesarrollo === id_desarrollo
      );
      if (desarrolloSeleccionado) {
        setDesarrolloSelec(desarrolloSeleccionado);
      }
    }
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
    });

    if (refresh) getProspectos();
  };

  const tableColumns_prospectos = prospectsColumns(
    handleEditarProspecto,
    handleEliminarProspecto,
    handleCambiarStatusProspecto,
    handleClonarProspecto,
    otrosUsuariosInfo
  );

  useEffect(() => {
    if (desarrollos.length > 0) {
      setDesarrolloSelec(desarrollos);
    }
  }, [desarrollos]);

  useEffect(() => {
    if (desarrollos.length > 0 && Object.keys(desarrolloSelec).length !== 0) {
      getProspectos();
      getCanales();
    }
  }, [desarrolloSelec, otrosUsuariosInfo]);

  useEffect(() => {
    if (embudos.length > 0) {
      let etapas = {};

      embudos.forEach((item) => {
        etapas[item.funnelId] = item.funnelName;
      });
      setStatusList(etapas);
    }
  }, [embudos]);

  useEffect(() => {
    if (motivos.length > 0) {
      let reasons = {
        0: "Sin comentario",
      };

      motivos.forEach((item) => {
        reasons[item.reasonId] = item.reasonName;
      });

      reasons["otros"] = "Otros...";

      setReasonsList(reasons);
    }
  }, [motivos]);

  return (
    <>
      <Breadcrumbs parent="Gestión" title="Prospectos" mainTitle="Prospectos" />
      <Container fluid={true}>
        <Card style={{minHeight: `calc(90vh - 140px)`}}>
          <Row>
            <Col sm="12">
              <Row className="m-20">
                <ProspectFilters
                  developments={desarrollos}
                  selectedDeveloment={desarrolloSelec}
                  setSelectedDevelopment={setDesarrolloSelec}
                  stage={etapasFilter}
                  setStage={setEtapaFilter}
                  setOtherUsersInfo={setOtrosUsauriosInfo}
                  otherUsersInfo={otrosUsuariosInfo}
                  isLoading={loadingEmbudos}
                  emailRecuperacion={emailRecuperacion}
                  setEmailRecuperacion={setEmailRecuperacion}
                  loadingProspectos={loadingProspectos}
                />
              </Row>
              <Col>
                <Row className="m-l-20 m-r-20 m-b-10">
                  <Col sm="7">
                    <div className="product-search feature-products">
                      <Form>
                        <FormGroup className="m-0 form-group search-product">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Buscar..."
                            value={searchTerm}
                            onChange={handleInputChange}
                          />
                          <i className="fa fa-search" />
                        </FormGroup>
                      </Form>
                    </div>
                  </Col>
                  <Col sm="5">
                    <ToolbarButtons
                      filename="Prospectos"
                      toggleCreate={toggleCreate}
                      showModal={handleShowModal}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="m-l-20 m-r-20 m-b-8">
                <DataTableComponent
                  tableColumns={tableColumns_prospectos}
                  tableRows={filteredData}
                  loadingData={loadingProspectos}
                  pagination={true}
                  scrollHeight={"calc(80vh - 230px)"}
                  minHeight={`calc(75vh - 230px)`}
                />
              </Col>
            </Col>
          </Row>
        </Card>
      </Container>

      {modalCreate && (
        <ProspectCreateModal
          modal={true}
          title={"Nuevo prospecto"}
          toggle={toggleCreate}
          size="lg"
          reload={reload}
          p_desarrollos={desarrollos}
          p_desarrollo={desarrolloSelec}
          fuentes={canales}
          loadingFuentes={loadingCanales}
          etapas={embudos.map((item) => {
            return {
              label: item.funnelName,
              value: item.funnelId,
            };
          })}
          loadingEtapas={loadingEmbudos}
        />
      )}
      {modalEdit && (
        <ProspectEditModal
          modal={true}
          toggle={toggleEdit}
          reload={reload}
          prospecto={prospecto}
          p_desarrollos={desarrollos}
          fuentes={canales}
          loadingFuentes={loadingCanales}
          etapas={embudos.map((item) => {
            return {
              label: item.funnelName,
              value: item.funnelId,
            };
          })}
          loadingEtapas={loadingEmbudos}
        />
      )}
      {modalClone && (
        <ProspectoFormClone
          modal={true}
          title={"Añadir prospecto a otro desarrollo"}
          toggle={toggleClone}
          size="md"
          reload={reload}
          prospecto={prospecto}
          p_desarrollos={desarrollos}
          etapas={embudos.map((item) => {
            return {
              label: item.funnelName,
              value: item.funnelId,
            };
          })}
        />
      )}
      {showModal && (
        <ModalContainer modal={true} open={handleShowModal} size="md">
          <FilterRangeDate
            onClose={handleShowModal}
            onExcelClick={fetchReport}
            filename="Prospectos"
            to={to}
            from={from}
            setTo={setTo}
            setFrom={setFrom}
          />
        </ModalContainer>
      )}
    </>
  );
};

export default ProspectosPage;
