import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Request from '../../api/httpClient';

const request = new Request();

const useUsersFormEdit = (usuario, reload, toggle) => {
   const userId = localStorage.getItem('user_id') || 0;
   const userRole = localStorage.getItem('Role');

   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const [nombre, setNombre] = useState(usuario.username);
   const [email, setEmail] = useState(usuario.email);
   const [telefono, setTelefono] = useState(usuario.phone);
   const [isLoading, setIsLoading] = useState(false);
   const [internalAdvisors, setInternalAdvisors] = useState([]);

   const tipoUsuarios = [
      { value: 'A', label: 'Administrador' },
      { value: 'V', label: 'Asesor Interno' },
      { value: 'S', label: 'Asesor Externo' },
      { value: 'M', label: 'Agencia' },
   ];
   const [tipoUsuario, setTipoUsuario] = useState(
      tipoUsuarios.find((tipo) => tipo.value === usuario.roll)
   );

   const comisiones = [
      { value: 2, label: 2 },
      { value: 3, label: 3 },
      { value: 4, label: 4 },
      { value: 5, label: 5 },
   ];
   const [comision, setComision] = useState(
      comisiones.find((com) => com.value === usuario.comision)
   );

   const tipoAsesores = [
      { value: 'Interno', label: 'Interno' },
      { value: 'Externo', label: 'Externo' },
   ];
   const tipoAsesor = tipoAsesores.find((tipo) => tipo.value === usuario.sellerType);

   const fetchInternalAdvisors = async () => {
      setInternalAdvisors([]);
      setIsLoading(true);

      const response = await request.get('/usuarios/internal-advisors');

      if (response && !response.error) {
         if (!response.empty) {
            setInternalAdvisors(response);
         } else {
            setInternalAdvisors([]);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setIsLoading(false);
   };

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitDisabled(true);

      const editionDate = new Date();

      let data = {
         id_usuario: usuario.pkuser,
         nombre: nombre,
         email: email,
         telefono: telefono,
         celular: telefono,
         tipoUsuario: tipoUsuario.value,
         comision: comision.value,
         tipoAsesor: tipoAsesor.value,
         currentPhone: usuario.phone,
         currentEmail: usuario.email,
         edit_date: editionDate,
         editor_id: userId,
      };

      const response = await request.post('/usuarios/usuario/update', data);

      if (response && !response.error) {
         toggle();
         setIsSubmitDisabled(false);
         reload('Usuario editado con éxito');
      } else {
         setIsSubmitDisabled(false);
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   useEffect(() => {
      fetchInternalAdvisors();
   }, []);

   return {
      nombre,
      setNombre,
      email,
      setEmail,
      telefono,
      setTelefono,
      isSubmitDisabled,
      isLoading,
      internalAdvisors,
      tipoUsuario,
      setTipoUsuario,
      comision,
      setComision,
      tipoAsesor,
      handleSubmit,
      userRole,
   };
};

export default useUsersFormEdit;
