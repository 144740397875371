import {Button} from "reactstrap";
import useExcel from "../../../Hooks/useExcel";
import moment from "moment-timezone";

const ToolbarButtons = ({
   toggleCreate,
   onExcelClick,
   onFilterClick,
   filename,
   showModal,
}) => {
   const {handleExport2} = useExcel();

   const handleExcel = async () => {
      const data = await onExcelClick();
      handleExport2(data, `${filename}_${moment().format("DDMMYYYY")}`);
   };

   return (
      <div className="text-end btn-showcase">
         {onExcelClick && (
            <Button
               color="excel"
               onClick={handleExcel}
               style={{
                  minWidth: 120,
                  marginRight: 10,
               }}
            >
               <i className="fa fa-file-excel-o" /> Excel
            </Button>
         )}
         {showModal && (
            <Button
               color="excel"
               onClick={showModal}
               style={{
                  minWidth: 120,
                  marginRight: 10,
               }}
            >
               <i className="fa fa-file-excel-o" /> Excel
            </Button>
         )}
         {toggleCreate && (
            <Button
               color="primary"
               onClick={toggleCreate}
               style={{
                  minWidth: 120,
               }}
            >
               <i className="fa fa-plus-square" /> Nuevo
            </Button>
         )}
         {onFilterClick && (
            <Button
               color="info"
               onClick={onFilterClick}
               style={{
                  minWidth: 120,
               }}
            >
               <i className="fa fa-check" /> Aplicar
            </Button>
         )}
      </div>
   );
};

export default ToolbarButtons;
