import MotivosContext from "./index";
import React, { useEffect, useState } from "react";
import Request from "../../api/httpClient";

const MotivosProvider = (props) => {
  const [motivos, setMotivos] = useState([]);
  const [loadingMotivos, setLoadingMotivos] = useState(true);
  const request = new Request();

  const getMotivos = async () => {
    setMotivos([]);
    setLoadingMotivos(true);
    const response = await request.get("/motivos/get/all");
    if (response && !response.error) {
      if (response && !response.empty) {
        setMotivos(response);
      } else {
        setMotivos([]);
        setLoadingMotivos(false);
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingMotivos(false);
  };

  useEffect(() => {
    getMotivos();
  }, []);

  return (
    <MotivosContext.Provider
      value={{ motivos, setMotivos, loadingMotivos, getMotivos }}
    >
      {props.children}
    </MotivosContext.Provider>
  );
};

export default MotivosProvider;
