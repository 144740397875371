import { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import Request from '../../api/httpClient';

const request = new Request();

const EMINDER = [
   { value: 0, label: 'No' },
   { value: 1, label: 'Si' },
];

const useTracingEdit = (tracing, reload, handleModal) => {
   const [isLoading, setIsLoading] = useState(false);
   const [subject, setSubject] = useState(tracing.subject);
   const [details, setDetails] = useState(tracing.detailnotes);
   const [selectedReminder, setSelectedReminder] = useState(tracing.rEvento);

   const [year, month, day] = tracing.fEvento.split('-').map(Number);
   const [eventDate, setEventDate] = useState(
      new Date(Date.UTC(year, month - 1, day + 1))
   );

   const [hour, minute, second] = tracing.hEvento.split(':').map(Number);
   const [eventTime, setEventTime] = useState(
      new Date(Date.UTC(year, month, day, hour + 5, minute, second))
   );

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsLoading(true);

      const fechaHoy = moment().format('YYYY-MM-DD');

      const data = {
         id_prospecto: tracing.fkclient,
         fechaVencimiento: moment(fechaHoy).add(45, 'days').format('YYYY-MM-DD'),
         asunto: subject,
         detalles: details,
         rEvento: selectedReminder.value !== undefined ? selectedReminder.value : 0,
         fEvento: moment(eventDate).format('YYYY-MM-DD'),
         hEvento: moment(eventTime).format('HH:mm:ss'),
         id_seguimiento: tracing.pkbinnacle,
      };

      const response = await request.post(
         '/prospectos/prospecto/seguimiento/update',
         data
      );

      if (response && !response.error) {
         handleModal('edit');
         reload('Seguimiento editado con éxito');
      } else {
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }

      setIsLoading(false);
   };

   return {
      EMINDER,
      isLoading,
      subject,
      setSubject,
      details,
      setDetails,
      selectedReminder,
      setSelectedReminder,
      eventDate,
      setEventDate,
      eventTime,
      setEventTime,
      handleSubmit,
   };
};

export { EMINDER, useTracingEdit };
