import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
   Container,
   Row,
   Col,
   Card,
   CardBody,
   FormGroup,
   Input,
   InputGroup,
   InputGroupText,
} from 'reactstrap';
import { Breadcrumbs, Btn, H6 } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
import { Editor } from '@tinymce/tinymce-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EmbudosContext from '../../_helper/Embudos';
import useFunnels from '../../Hooks/funnels/useFunnels';

const EmbudosPage = () => {
   const { embudos, loadingEmbudos, setEmbudos } = useContext(EmbudosContext);
   const {
      handleEliminarEmbudo,
      handleNuevoEmbudo,
      handleEditarEmbudo,
      handleGetAction,
      handleUpdateAction,
      setAsuntoAccion,
      setCuerpoAccion,
      asunto_accion,
      cuerpo_accion,
   } = useFunnels();
   const [nuevoEmbudo, setNuevoEmbudo] = useState('');
   const [modifiedIndex, setModifiedIndex] = useState(null);
   const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

   useEffect(() => {
      if (embudos.length > 0 && selectedButtonIndex == null) {
         setSelectedButtonIndex(0);
         handleGetAction(embudos[0]);
         setNuevoEmbudo('');
      }
   }, [embudos]);

   const handleInputChange = (e, index) => {
      const { value } = e.target;
      setEmbudos((prevEmbudos) => {
         const newEmbudos = [...prevEmbudos];
         newEmbudos[index].funnelName = value;
         return newEmbudos;
      });
      setModifiedIndex(index);
   };

   const handleOnDragEnd = (result) => {
      const items = Array.from(embudos);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setEmbudos(items);
   };

   return (
      <Fragment>
         <Breadcrumbs parent="Gestión" title="Embudos" mainTitle="Embudos" />
         <Container fluid={true}>
            <Row>
               <Col xl="4" md="6 box-col-6">
                  <Card>
                     <HeaderCard title="Embudos" />
                     <CardBody>
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                           <Droppable droppableId="characters">
                              {(provided) => (
                                 <div
                                    className="characters"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    {embudos.map((embudo, index) => (
                                       <Draggable
                                          key={embudo.funnelId}
                                          draggableId={embudo.funnelId.toString()}
                                          index={index}>
                                          {(provided) => (
                                             <div
                                                className="row m-t-10"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <Col xs="7" md="7">
                                                   <InputGroup>
                                                      <InputGroupText>
                                                         <i
                                                            className="fa fa-bars"
                                                            aria-hidden="true"></i>
                                                      </InputGroupText>
                                                      <Input
                                                         id={embudo.funnelId}
                                                         type="text"
                                                         value={embudo.funnelName}
                                                         onChange={(e) =>
                                                            handleInputChange(e, index)
                                                         }
                                                      />
                                                   </InputGroup>
                                                </Col>

                                                <Col xs="5" md="5">
                                                   <Btn
                                                      attrBtn={{
                                                         color: 'primary',
                                                         style: {
                                                            padding: '5px 10px',
                                                            marginTop: '3px',
                                                         },
                                                         onClick: () => {
                                                            handleGetAction(embudo);
                                                            setSelectedButtonIndex(index);
                                                         },
                                                      }}>
                                                      <i
                                                         className={`fa ${
                                                            selectedButtonIndex === index
                                                               ? 'fa-envelope-open'
                                                               : 'fa-envelope'
                                                         }`}></i>
                                                   </Btn>
                                                   <Btn
                                                      attrBtn={{
                                                         color: 'secondary',
                                                         style: {
                                                            padding: '5px 10px',
                                                            marginTop: '3px',
                                                            marginLeft: '5px',
                                                         },
                                                         onClick: () => {
                                                            handleEliminarEmbudo(embudo);
                                                         },
                                                      }}>
                                                      <i className="fa fa-trash"></i>
                                                   </Btn>
                                                   {modifiedIndex === index && (
                                                      <Btn
                                                         attrBtn={{
                                                            color: 'success',
                                                            style: {
                                                               padding: '5px 10px',
                                                               marginTop: '3px',
                                                               marginLeft: '5px',
                                                            },
                                                            onClick: () => {
                                                               handleEditarEmbudo(
                                                                  modifiedIndex
                                                               );
                                                               setModifiedIndex(null);
                                                            },
                                                         }}>
                                                         <i className="fa fa-check"></i>
                                                      </Btn>
                                                   )}
                                                </Col>
                                             </div>
                                          )}
                                       </Draggable>
                                    ))}
                                    {provided.placeholder}
                                 </div>
                              )}
                           </Droppable>
                        </DragDropContext>

                        <FormGroup className="row m-t-10">
                           <Col xs="7" md="7">
                              <Input
                                 id="nuevo"
                                 type="text"
                                 value={nuevoEmbudo}
                                 onChange={(e) => {
                                    setNuevoEmbudo(e.target.value);
                                 }}
                                 placeholder="Nuevo embudo"
                              />
                           </Col>
                           <Col xs="5" md="5">
                              <Btn
                                 attrBtn={{
                                    color: 'info',
                                    style: {
                                       padding: '5px 10px',
                                       marginTop: '3px',
                                    },
                                    onClick: () => {
                                       handleNuevoEmbudo(nuevoEmbudo);
                                       setNuevoEmbudo('');
                                    },
                                 }}>
                                 <i className="fa fa-plus"></i>
                              </Btn>
                           </Col>
                        </FormGroup>
                     </CardBody>
                  </Card>
               </Col>

               <Col xl="8" className="box-col-6">
                  <Card>
                     <HeaderCard title="Plantilla de correo" />
                     {embudos.length > 0 && !loadingEmbudos ? (
                        <CardBody>
                           <FormGroup>
                              <H6 attrH6={{ className: 'text-muted' }}>
                                 Asunto del embudo
                              </H6>
                              <Input
                                 type="text"
                                 value={asunto_accion}
                                 onChange={(e) => {
                                    setAsuntoAccion(e.target.value);
                                 }}
                              />
                           </FormGroup>
                           <H6 attrH6={{ className: 'text-muted or mt-4' }}>
                              Cuerpo de la acción
                           </H6>
                           <Editor
                              value={cuerpo_accion}
                              onEditorChange={(cuerpo) => {
                                 setCuerpoAccion(cuerpo);
                              }}
                              apiKey="sphdt0imj43tqwqei1iuvhsj10gqi6ml01jzbzp8ql746gec"
                              init={{
                                 plugins:
                                    'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                 toolbar:
                                    'undo redo | blocks fontfamily fontsize | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code | pagebreak underline strikethrough | fontselect fontsizeselect | checklist | forecolor casechange permanentpen formatpainter removeformat  | charmap emoticons | fullscreen  preview save print | insertfile media pageembed template anchor codesample  | showcomments addcomment',
                              }}
                           />
                           <Row
                              style={{
                                 display: 'flex',
                                 justifyContent: 'center',
                              }}>
                              <Btn
                                 attrBtn={{
                                    color: 'success',
                                    style: {
                                       padding: '5px 10px',
                                       marginTop: '20px',
                                       width: 'auto',
                                    },
                                    onClick: () => {
                                       handleUpdateAction(selectedButtonIndex);
                                    },
                                 }}>
                                 Guardar cambios
                              </Btn>
                           </Row>
                        </CardBody>
                     ) : (
                        <CardBody>
                           <div className="loader-box">
                              <div className="loader-15" />
                           </div>
                        </CardBody>
                     )}
                  </Card>
               </Col>
            </Row>
         </Container>
      </Fragment>
   );
};

export default EmbudosPage;
