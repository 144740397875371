import React, {useEffect, useState} from "react";
import {Col} from "react-bootstrap";
import {Form, FormGroup, Label, Row} from "reactstrap";
import CommonModal from "../UiKits/Modals/common/modal";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Btn} from "../../AbstractElements";
import Request from "../../api/httpClient";
import Select from "react-select";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {toast} from "react-toastify";

const ProspectoFormClone = ({
   modal,
   title,
   toggle,
   size,
   reload,
   p_desarrollos,
   prospecto,
   etapas,
}) => {
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const [etapa, setEtapa] = useState(etapas[0]);

   const desarrollos = p_desarrollos
      .filter((item) => item.IDdesarrollo !== prospecto.desarrolloID)
      .map((item) => {
         return {
            label: item.nomDesarrollo,
            value: item.IDdesarrollo,
         };
      });
   const [desarrollo, setDesarrollo] = useState(null);
   const [departamentos, setDepartamentos] = useState([]);
   const [departamentosSelec, setDepartamentosSelec] = useState(null);
   const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);

   const request = new Request();

   useEffect(() => {
      if (desarrollo) {
         getDepartamentos();
      }
   }, [desarrollo]);

   const handleSubmit = async (event) => {
      event.preventDefault();
      setIsSubmitDisabled(true);

      let data = {
         nombre_usuario: localStorage.getItem("Name") || "",
         id_usuario: localStorage.getItem("user_id") || 0,
         cliente: prospecto.client,
         email: prospecto.emailclient,
         telefono: prospecto.phoneclient,
         id_embudo: etapa.value,
         id_prospecto: prospecto.pkclient,
         id_desarrollo: desarrollo.value,
         interes: departamentosSelec && departamentosSelec.label,
      };

      const response = await request.post("/prospectos/prospecto/clone", data);

      if (response && !response.error) {
         toggle();
         setIsSubmitDisabled(false);
         reload(
            true,
            "Prospecto añadido al desarrollo " + desarrollo.label,
            desarrollo.value
         );
      } else {
         setIsSubmitDisabled(false);
         toast.error(response.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
         });
      }
   };

   const getDepartamentos = async () => {
      setDepartamentos([]);
      setLoadingDepartamentos(true);
      const data = {
         id_desarrollo: desarrollo.value,
      };
      const response = await request.post(
         "/prospectos/get/departamentos",
         data
      );
      if (response && !response.error) {
         if (response && !response.empty) {
            const dptos = response.map((dpto) => {
               return {
                  value: dpto.pkdepto,
                  label: dpto.depto,
               };
            });
            setDepartamentos(dptos);
         } else {
            setDepartamentos([]);
            setLoadingDepartamentos(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setLoadingDepartamentos(false);
   };

   return (
      <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
         <Modal isOpen={modal} toggle={toggle} size={size} centered>
            <Form onSubmit={handleSubmit}>
               <ModalHeader toggle={toggle}>{title}</ModalHeader>
               <ModalBody style={{paddingTop: "2px", paddingBottom: "2px"}}>
                  <Row>
                     <Col sm="12" md="12">
                        <div
                           className="project-box"
                           style={{paddingBottom: "5px"}}
                        >
                           <div>
                              <div>
                                 <FormGroup className="row">
                                    <Col md="12">
                                       <Label className="col-form-label text-start">
                                          Desarrollo:
                                       </Label>
                                       <Select
                                          options={desarrollos}
                                          placeholder={"Seleccione..."}
                                          onChange={(desarr) =>
                                             setDesarrollo(desarr)
                                          }
                                          required
                                       />
                                    </Col>
                                    <Col md="12">
                                       <Label className="col-form-label text-start">
                                          Interesados en:
                                       </Label>
                                       <Select
                                          options={departamentos}
                                          onChange={(depts) =>
                                             setDepartamentosSelec(depts)
                                          }
                                          placeholder={"Seleccione..."}
                                          isLoading={loadingDepartamentos}
                                          isClearable
                                       />
                                    </Col>
                                    <Col sm="12">
                                       <Label className="col-form-label text-start">
                                          Etapa:
                                       </Label>
                                       <Select
                                          value={etapa}
                                          options={etapas}
                                          placeholder={"Seleccione..."}
                                          onChange={(etapa) => setEtapa(etapa)}
                                          required
                                       />
                                    </Col>
                                 </FormGroup>
                              </div>
                           </div>
                        </div>
                     </Col>
                  </Row>
               </ModalBody>
               <ModalFooter>
                  <Btn attrBtn={{color: "secondary", onClick: toggle}}>
                     Cancelar
                  </Btn>
                  <Btn
                     attrBtn={{
                        type: "submit",
                        color: "primary",
                        disabled: isSubmitDisabled,
                     }}
                  >
                     {isSubmitDisabled ? "Guardando..." : "Guardar"}
                  </Btn>
               </ModalFooter>
            </Form>
         </Modal>
      </CommonModal>
   );
};

export default ProspectoFormClone;
