import { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import Request from '../../api/httpClient';

const request = new Request();

const useZapierEditForm = ({ lead, toggle, fetchZapier }) => {
   const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [internalAdvisors, setInternalAdvisors] = useState([]);
   const [selectedInternalAdvisor, setSelectedInternalAdvisor] = useState('');

   const fetchInternalAdvisors = async () => {
      setInternalAdvisors([]);
      setIsLoading(true);
      const response = await request.get('/usuarios/internal-advisors');
      if (response && !response.error) {
         setInternalAdvisors(response.empty ? [] : response);
      } else {
         console.error(response.message);
         toast.error('Error al cargar asesores internos', { position: 'bottom-right' });
      }
      setIsLoading(false);
   };

   const handleSubmit = async () => {
      if (!selectedInternalAdvisor) {
         toast.error('Selecciona un asesor interno', {
            position: 'bottom-right',
            autoClose: 2000,
         });
         return;
      }

      setIsSubmitDisabled(true);
      const date = moment().format('YYYY-MM-DD');
      const advisor = internalAdvisors.find((a) => a.value === selectedInternalAdvisor);

      const data = {
         id: lead.id,
         advisorId: advisor?.id || 0,
         date: date,
         expirationDate: moment(date).add(45, 'days').format('YYYY-MM-DD'),
         advisorName: advisor?.label || '',
         leadName: `${lead.Nombre} ${lead.Apellido}`,
         leadEmail: lead?.Email || '',
         leadPhone: lead?.WhatsApp || '',
         interest: '',
         developmentId: lead.development || 5,
         stageId: 1,
      };

      const response = await request.post('/prospectos/assign/internal-advisor', data);
      setIsSubmitDisabled(false);

      if (response && !response.error) {
         toggle();
         toast.success('Asesor interno asignado con éxito', {
            position: 'bottom-right',
            autoClose: 2000,
         });
         fetchZapier();
      } else {
         toast.error(response.message, { position: 'bottom-right', autoClose: 2000 });
      }
   };

   useEffect(() => {
      fetchInternalAdvisors();
   }, []);

   return {
      isSubmitDisabled,
      isLoading,
      internalAdvisors,
      selectedInternalAdvisor,
      setSelectedInternalAdvisor,
      handleSubmit,
   };
};

export default useZapierEditForm;
