import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import LoginForm from './LoginForm';
import Logo from '../../../assets/images/login/logo-login.png';

const LoginOne = () => {
   return (
      <Fragment>
         <section>
            <Container fluid={true}>
               <Row>
                  <Col
                     xl="5"
                     className="b-center bg-size "
                     style={{
                        backgroundImage: `url(${Logo})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'block',
                     }}></Col>
                  <Col xl="7 p-0">
                     <LoginForm logoClassMain="text-start" />
                  </Col>
               </Row>
            </Container>
         </section>
      </Fragment>
   );
};

export default LoginOne;
