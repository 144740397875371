import React from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import DeleteIcon from '@mui/icons-material/Delete';

const RemindersColumns = ({ handleEliminarRecordatorio }) => {
   const tableColumns = [
      {
         name: <div>Prospecto</div>,
         minWidth: '80px',
         maxWidth: '150px',
         selector: (row) => `${row.client}`,
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%' }}>Datos de contacto</div>,
         minWidth: '200px',
         maxWidth: '300px',
         selector: (row) => (
            <Row className="details">
               <Col xs="4">
                  <span className="f-w-500">Teléfono: </span>
               </Col>
               <Col xs="8">{row.phoneclient}</Col>
               <Col xs="4">
                  <span className="f-w-500">Correo:</span>
               </Col>
               <Col xs="8">{row.emailclient}</Col>
            </Row>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%' }}>Detalles</div>,
         minWidth: '400px',
         maxWidth: '700px',
         selector: (row) => (
            <Row className="details">
               <Col xs="4">
                  <span className="f-w-500">Fecha evento: </span>
               </Col>
               <Col xs="8">{moment(row.fEvento).format('DD-MM-YYYY')}</Col>
               <Col xs="4">
                  <span className="f-w-500">Asunto:</span>
               </Col>
               <Col xs="8">{row.subject}</Col>
               <Col xs="4">
                  <span className="f-w-500">Detalles:</span>
               </Col>
               <Col xs="8">{row.detailnotes}</Col>
            </Row>
         ),
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Seguimiento</div>,
         minWidth: '50px',
         maxWidth: '120px',
         selector: (row) => `${row.historial}`,
         sortable: true,
         center: false,
      },
      {
         name: <div style={{ width: '100%', textAlign: 'center' }}>Vigente</div>,
         minWidth: '80px',
         maxWidth: '120px',
         cell: (row) => (
            <div style={{ width: '100%', textAlign: 'center' }}>
               {new Date(row.fEvento) < new Date() ? (
                  <span className="font-danger">
                     <i className="icofont icofont-close"></i>
                  </span>
               ) : (
                  <span className="font-success">
                     <i className="icofont icofont-check"></i>
                  </span>
               )}
            </div>
         ),
         sortable: true,
         center: false,
      },
      {
         name: '',
         minWidth: '50px',
         button: true,
         ignoreRowClick: true,
         allowOverflow: true,
         center: false,
         cell: (row) => (
            <Col
               xl="6"
               md="12"
               style={{
                  display: new Date(row.fEvento) < new Date() ? 'block' : 'none',
               }}
               onClick={() => {
                  handleEliminarRecordatorio(row);
               }}>
               <DeleteIcon fontSize="small" color="error" />
            </Col>
         ),
      },
   ];

   return tableColumns;
};

export default RemindersColumns;
