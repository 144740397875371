import "react-bootstrap-typeahead/css/Typeahead.css";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import ProspectTabs from "../ProspectTabs/ProspectTabs";

const ProspectEditModal = ({
   modal,
   toggle,
   reload,
   p_desarrollos,
   prospecto,
   fuentes,
   etapas,
}) => {
   return (
      <Modal isOpen={modal} toggle={toggle} size="xl" centered>
         <ModalHeader toggle={toggle}></ModalHeader>
         <ModalBody
            style={{
               minHeight: "400px",
            }}
         >
            <ProspectTabs
               reload={reload}
               p_desarrollos={p_desarrollos}
               prospecto={prospecto}
               fuentes={fuentes}
               etapas={etapas}
               toggle={toggle}
            />
         </ModalBody>
      </Modal>
   );
};

export default ProspectEditModal;
