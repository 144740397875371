import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Request from '../../api/httpClient';

const UseRecovery = () => {
   const [recuperacion, setRecuperacion] = useState([]);
   const [modifiedIndex, setModifiedIndex] = useState(null);
   const request = new Request();

   const getRecuperacion = async () => {
      setRecuperacion([]);
      const response = await request.get('/recuperacion/get/all');
      if (response && !response.error) {
         if (response && !response.empty) {
            setRecuperacion(response);
         } else {
            setRecuperacion([]);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
   };

   const handleEditarRecuperacionAsunto = () => {
      Swal.fire({
         title: '¿Está seguro de editar este asunto?',
         text: 'Se editará el asunto: ' + recuperacion[modifiedIndex].asuntoCorreo,
         icon: 'info',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Editar',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_correo: recuperacion[modifiedIndex].idCorreo,
               asunto_correo: recuperacion[modifiedIndex].asuntoCorreo,
            };

            const response = await request.post('/recuperacion/asunto/update', data);
            if (response && !response.error) {
               Swal.fire('¡Editado!', 'Asunto editado con éxito', 'success');
               getRecuperacion();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleInputChange = (e, index) => {
      const { value } = e.target;
      setRecuperacion((prevRecuperacion) => {
         const newRecuperacion = [...prevRecuperacion];
         newRecuperacion[index].asuntoCorreo = value;
         return newRecuperacion;
      });
      setModifiedIndex(index);
   };

   useEffect(() => {
      getRecuperacion();
   }, []);

   return {
      recuperacion,
      modifiedIndex,
      setModifiedIndex,
      handleEditarRecuperacionAsunto,
      handleInputChange,
   };
};

export default UseRecovery;
