import {Container} from "reactstrap";
import Login from "../Components/Pages/Auth/Login";

const Logins = () => {
   return (
      <Container fluid={true} className="p-0 login-page">
         <Login />
      </Container>
   );
};

export default Logins;
