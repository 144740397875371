import moment from "moment";

export const ReportsColumns = () => [
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Registro</div>,
      excelname: "Registro",
      minWidth: "120px",
      width: 15,
      selector: (row) => `${moment(row.registerclient).format("DD-MM-YYYY")}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Prospecto</div>,
      excelname: "Nombre",
      minWidth: "160px",
      width: 25,
      selector: (row) => `${row.client}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Desarrollo</div>,
      excelname: "Fuente",
      minWidth: "120px",
      width: 25,
      selector: (row) => `${row.nomDesarrollo}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Teléfono</div>,
      excelname: "Teléfono",
      minWidth: "120px",
      width: 15,
      selector: (row) => `${row.phoneclient}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Correo</div>,
      excelname: "Correo",
      minWidth: "180px",
      width: 25,
      selector: (row) => `${row.emailclient}`,
      cell: (row) => (
         <div style={{width: "100%", textAlign: "left"}}>{row.emailclient}</div>
      ),
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Ciudad</div>,
      excelname: "Ciudad",
      minWidth: "120px",
      width: 18,
      selector: (row) => `${row?.city || ""}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Asesor</div>,
      excelname: "Asesor",
      minWidth: "150px",
      width: 25,
      selector: (row) => `${row.username}`,
      sortable: true,
      center: false,
   },
];
