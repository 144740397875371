import React from 'react';
import { Form, FormGroup, Input, Label, Col, Row } from 'reactstrap';
import CommonModal from '../UiKits/Modals/common/modal';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Btn } from '../../AbstractElements';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import useUsersChangePassword from '../../Hooks/users/useUsersChangePassword';

const UsuarioChangePassword = ({
   modal,
   title,
   toggle,
   size,
   reload,
   id_usuario,
   email,
}) => {
   const {
      isSubmitDisabled,
      newPassword,
      setNewPassword,
      newPasswordConfirm,
      setNewPasswordConfirm,
      showPassword,
      setShowPassword,
      showPasswordConfirm,
      setShowPasswordConfirm,
      handleSubmit,
   } = useUsersChangePassword(id_usuario, email, reload, toggle);

   return (
      <CommonModal isOpen={modal} title={title} toggler={toggle} size={size}>
         <Modal isOpen={modal} toggle={toggle} size={size} centered>
            <Form onSubmit={handleSubmit}>
               <ModalHeader toggle={toggle}>{title}</ModalHeader>
               <ModalBody style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                  <Row>
                     <Col sm="12" md="12">
                        <div className="project-box" style={{ paddingBottom: '5px' }}>
                           <div>
                              <div>
                                 <FormGroup>
                                    <Label for="newpassword">Nueva contraseña:</Label>
                                    <div className="input-group">
                                       <Input
                                          id="newpassword"
                                          type={showPassword ? 'text' : 'password'}
                                          onChange={(e) => setNewPassword(e.target.value)}
                                          minLength="6"
                                          required
                                       />
                                       <button
                                          type="button"
                                          className="btn btn-light"
                                          onClick={() => setShowPassword(!showPassword)}>
                                          {showPassword ? (
                                             <FaEye style={{ fill: 'darkgray' }} />
                                          ) : (
                                             <FaEyeSlash style={{ fill: 'darkgray' }} />
                                          )}
                                       </button>
                                    </div>
                                 </FormGroup>
                                 <FormGroup>
                                    <Label className="col-form-label text-start">
                                       Confirmar nueva contraseña:
                                    </Label>

                                    <div className="input-group">
                                       <Input
                                          id="newpassword_confirm"
                                          type={showPasswordConfirm ? 'text' : 'password'}
                                          onChange={(e) => {
                                             setNewPasswordConfirm(e.target.value);
                                          }}
                                          minLength="6"
                                          required
                                       />
                                       <button
                                          type="button"
                                          className="btn btn-light"
                                          onClick={() =>
                                             setShowPasswordConfirm(!showPasswordConfirm)
                                          }>
                                          {showPasswordConfirm ? (
                                             <FaEye style={{ fill: 'darkgray' }} />
                                          ) : (
                                             <FaEyeSlash style={{ fill: 'darkgray' }} />
                                          )}
                                       </button>
                                    </div>
                                 </FormGroup>
                                 {newPassword &&
                                    newPasswordConfirm &&
                                    (newPassword === newPasswordConfirm ? (
                                       <span style={{ color: 'green' }}>
                                          Las contraseñas coinciden
                                       </span>
                                    ) : (
                                       <span style={{ color: 'red' }}>
                                          Las contraseñas no coinciden
                                       </span>
                                    ))}
                              </div>
                           </div>
                        </div>
                     </Col>
                  </Row>
               </ModalBody>
               <ModalFooter>
                  <Btn attrBtn={{ color: 'secondary', onClick: toggle }}>Cancelar</Btn>
                  <Btn
                     attrBtn={{
                        type: 'submit',
                        color: 'primary',
                        disabled: isSubmitDisabled,
                     }}>
                     {isSubmitDisabled ? 'Guardando...' : 'Guardar'}
                  </Btn>
               </ModalFooter>
            </Form>
         </Modal>
      </CommonModal>
   );
};

export default UsuarioChangePassword;
