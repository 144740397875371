// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect} from "react";
import moment from "moment";
import Request from "../../api/httpClient";
import {ReportsColumns} from "../../Data/Table/ReportsColumns";

const request = new Request();

const useReports = () => {
   const columns = ReportsColumns();
   const [isLoading, setIsLoading] = useState(false);
   const [prospects, setProspects] = useState([]);
   const [showModal, setShowModal] = useState(false);
   const [from, setFrom] = useState(moment().clone().startOf("month").toDate());
   const [to, setTo] = useState(moment().clone().endOf("month").toDate());

   const handleModal = () => setShowModal(!showModal);

   const fetchProspects = async () => {
      setProspects([]);
      setIsLoading(true);
      const data = {
         fecha_inicio: moment(from).format("YYYY-MM-DD HH:mm:ss"),
         fecha_fin: moment(to).format("YYYY-MM-DD HH:mm:ss"),
      };

      const response = await request.post(
         "/reportes/get/historialMarketing",
         data
      );
      if (response && !response.error) {
         if (response && !response.empty) {
            setProspects(response);
         } else {
            setProspects([]);
            setIsLoading(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setIsLoading(false);
   };

   const handleReport = async () => {
      const data = {
         from: moment(from).format("YYYY-MM-DD 00:00:00"),
         to: moment(to).format("YYYY-MM-DD 23:59:59"),
      };

      const response = await request.post("/reportes/prospects", data);

      return response;
   };

   useEffect(() => {
      fetchProspects();
   }, []);

   return {
      columns,
      isLoading,
      prospects,
      showModal,
      handleModal,
      from,
      setFrom,
      to,
      setTo,
      fetchProspects,
      handleReport,
   };
};

export default useReports;
