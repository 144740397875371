import {useState} from "react";
import {toast} from "react-toastify";
import {MD5} from "crypto-js";
import Request from "../../api/httpClient";

const request = new Request();

const useUsersChangePassword = (id_usuario, email, reload, toggle) => {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitDisabled(true);

    if (newPassword === newPasswordConfirm) {
      const sanitizedPassword = newPassword.replace(/[^a-zA-Z0-9]/g, "");
      const reversedPassword = sanitizedPassword.split("").reverse().join("");
      const encryptedPassword = MD5(reversedPassword).toString();

      const data = {
        id_usuario: id_usuario,
        password: encryptedPassword,
        password_email: newPassword,
        email: email,
        username: localStorage.getItem("Name") || "",
      };

      try {
        const response = await request.post(
          "/usuarios/usuario/updatePassword",
          data
        );

        if (response && !response.error) {
          toggle();
          toast.success("Contraseña cambiada con éxito", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
          reload();
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      } finally {
        setIsSubmitDisabled(false);
      }
    } else {
      setIsSubmitDisabled(false);
      toast.error("Las contraseñas no coinciden", {});
    }
  };

  return {
    isSubmitDisabled,
    newPassword,
    setNewPassword,
    newPasswordConfirm,
    setNewPasswordConfirm,
    showPassword,
    setShowPassword,
    showPasswordConfirm,
    setShowPasswordConfirm,
    handleSubmit,
  };
};

export default useUsersChangePassword;
