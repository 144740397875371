import {Form, FormGroup, Input, Label, Row, Col} from "reactstrap";
import moment from "moment";

const ProspectZapierForm = ({prospect}) => {
  return (
    <Form className="project-form-modal">
      <FormGroup>
        <Row>
          <Col xl="6" sm="12">
            <Label className="col-form-label text-start">Fecha:</Label>
            <Input
              id="fecha"
              type="text"
              value={
                prospect.zfecha
                  ? moment(prospect.zfecha).format("DD/MM/YYYY")
                  : ""
              }
              disabled
            />
          </Col>
          <Col xl="6" sm="12">
            <Label className="col-form-label text-start">
              Teléfono Redes sociales:
            </Label>
            <Input
              id="redes"
              type="text"
              value={prospect.zphone === null ? "" : prospect.zphone}
              disabled
            />
          </Col>
          <Col xl="4" sm="12">
            <Label className="col-form-label text-start">Origen:</Label>
            <Input id="origen" type="text" value={prospect.zorigen} disabled />
          </Col>
          <Col xl="4" sm="12">
            <Label className="col-form-label text-start">Plataforma:</Label>
            <Input
              id="plataforma"
              type="text"
              value={prospect.zplataforma}
              disabled
            />
          </Col>
          <Col xl="4" sm="12">
            <Label className="col-form-label text-start">Campaña:</Label>
            <Input
              id="campaña"
              type="text"
              value={prospect.zcampania}
              disabled
            />
          </Col>
          <Col xl="6" sm="12">
            <Label className="col-form-label text-start">Adset:</Label>
            <Input id="adset" type="text" value={prospect.zadaset} disabled />
          </Col>
          <Col xl="6" sm="12">
            <Label className="col-form-label text-start">Adname:</Label>
            <Input id="adname" type="text" value={prospect.zadname} disabled />
          </Col>
          <Col xl="12" sm="12">
            <Label className="col-form-label text-start">Presupuesto:</Label>
            <Input
              id="presupuesto"
              type="text"
              value={prospect.zpresupuesto}
              disabled
            />
          </Col>
          <Col sm="12">
            <Label className="col-form-label text-start">Comentarios:</Label>
            <Input
              id="comentarios"
              type="textarea"
              className="form-control"
              rows="4"
              value={prospect.zcommentarios}
              disabled
            />
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default ProspectZapierForm;
