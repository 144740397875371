import React from "react";
import moment from "moment";
import ProspectListButton from "../../Components/shared/Buttons/ProspectListButton";
import UsuarioMenu from "../../Components/Usuarios/UsuarioMenu";

export const UsersColumns = (
   type,
   handleUserDelete,
   handleUserRecovery,
   handleUserEdit,
   handlePasswordEdit,
   handleProspectList
) => [
   {
      name: "Nombre",
      minWidth: "200px",
      selector: (row) => `${row.username}`,
      sortable: true,
      center: false,
   },
   {
      name: "Correo",
      minWidth: "250px",
      selector: (row) => `${row.email}`,
      sortable: true,
      center: false,
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Teléfono</div>,
      minWidth: "110px",
      selector: (row) => `${row.movil}`,
      sortable: true,
      center: true,
      cell: (row) => (
         <div style={{width: "100%", textAlign: "center"}}>
            {row.phone || "-"}
         </div>
      ),
   },
   {
      name: (
         <div style={{width: "100%", textAlign: "center"}}>
            Fecha de registro
         </div>
      ),
      minWidth: "250px",
      selector: (row) => `${moment(row.register).format("DD-MM-YYYY hh:mm A")}`,
      sortable: true,
      center: false,
   },
   type !== "M" && {
      name: <div style={{width: "100%", textAlign: "center"}}>Prospectos</div>,
      minWidth: "80px",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      center: true,
      cell: (row) => (
         <ProspectListButton row={row} onProspectListRow={handleProspectList} />
      ),
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Registros</div>,
      minWidth: "50px",
      selector: (row) => `${row.tipoRegistro}`,
      sortable: true,
      center: false,
      cell: (row) => (
         <div style={{width: "100%", textAlign: "right"}}>
            {row.tipoRegistro}
         </div>
      ),
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Comisión</div>,
      minWidth: "50px",
      selector: (row) => `${row.comision}`,
      sortable: true,
      center: false,
      cell: (row) => (
         <div style={{width: "100%", textAlign: "right"}}>{row.comision}</div>
      ),
   },
   {
      name: (
         <div style={{width: "100%", textAlign: "center"}}>No verificados</div>
      ),
      minWidth: "50px",
      selector: (row) => `${row.tipoRegistro}`,
      sortable: true,
      center: false,
      cell: (row) => (
         <div style={{width: "100%", textAlign: "right"}}>
            {row.tipoRegistro}
         </div>
      ),
   },
   {
      name: <div style={{width: "100%", textAlign: "center"}}>Activo</div>,
      minWidth: "10px",
      selector: (row) => `${row.state}`,
      cell: (row) => (
         <div style={{width: "100%", textAlign: "center"}}>
            {row.state ? (
               <span className="font-success">
                  <i className="icofont icofont-check"></i>
               </span>
            ) : (
               <span className="font-danger">
                  <i className="icofont icofont-close"></i>
               </span>
            )}
         </div>
      ),

      sortable: true,
      center: false,
   },
   {
      name: "",
      minWidth: "50px",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      center: false,
      cell: (row) => (
         <UsuarioMenu
            size="small"
            row={row}
            onDeleteRow={handleUserDelete}
            onUnDeleteRow={handleUserRecovery}
            onEditRow={handleUserEdit}
            onEditPasswordRow={handlePasswordEdit}
         />
      ),
   },
];
