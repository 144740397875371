import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const DesarrollosDropDown = ({
  dropdownMain,
  icon = true,
  iconName,
  btn,
  options,
  onOptionSelected, // función de devolución de llamada
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    IDdesarrollo: 0,
    nomDesarrollo: "Todos",
  });

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onOptionSelected(option); // envía la opción seleccionada al componente padre
  };

  return (
    <Dropdown {...dropdownMain} isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle {...btn} style={{ minWidth: "150px" }}>
        {icon && <i className={iconName}></i>}
        {!icon && selectedOption.nomDesarrollo}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() =>
            handleOptionClick({ IDdesarrollo: 0, nomDesarrollo: "Todos" })
          }
        >
          Todos
        </DropdownItem>
        {options.map((item, i) => (
          <DropdownItem key={i} onClick={() => handleOptionClick(item)}>
            {item.nomDesarrollo}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DesarrollosDropDown;
