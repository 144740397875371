import "react-bootstrap-typeahead/css/Typeahead.css";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import BrokerTabs from "./BrokerTabs/BrokerTabs";

const BrokerEditModal = ({modal, toggle, reload, prospecto, fuentes}) => {
   return (
      <Modal isOpen={modal} toggle={toggle} size="xl" centered>
         <ModalHeader toggle={toggle}></ModalHeader>
         <ModalBody
            style={{
               minHeight: "400px",
            }}
         >
            <BrokerTabs
               reload={reload}
               prospecto={prospecto}
               fuentes={fuentes}
               toggle={toggle}
            />
         </ModalBody>
      </Modal>
   );
};

export default BrokerEditModal;
