import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Request from '../../api/httpClient';

const request = new Request();

const useChannels = () => {
   const [canales, setCanales] = useState([]);
   const [modifiedIndex, setModifiedIndex] = useState(null);
   const [nombre, setNombre] = useState('');
   const [colorFondo, setColorFondo] = useState('#000000');
   const [colorTexto, setColorTexto] = useState('ffffff');

   useEffect(() => {
      getCanales();
   }, []);

   const getCanales = async () => {
      setCanales([]);
      const response = await request.get('/canales/get/all');
      if (response && !response.error) {
         if (response && !response.empty) {
            setCanales(response);
         } else {
            setCanales([]);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
   };

   const reload = (message) => {
      setNombre('');
      setColorFondo('#000000');
      setColorTexto('#ffffff');
      toast.success(message, {
         position: toast.POSITION.BOTTOM_RIGHT,
         autoClose: 2000,
      });
      getCanales();
   };

   const handleEliminarCanal = (row) => {
      Swal.fire({
         title: 'Está seguro de eliminar este canal?',
         text: 'Se eliminará el canal: ' + row.channelName,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Eliminar',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_canal: row.channelId,
            };
            const response = await request.post('/canales/canal/delete', data);
            if (response && !response.error) {
               Swal.fire('Eliminado!', 'Este canal ha sido eliminado', 'success');
               reload();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleNuevoCanal = () => {
      Swal.fire({
         title: '¿Está seguro de crear un nuevo canal?',
         text: 'Se creará el motivo: ' + nombre,
         icon: 'info',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Crear',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               nombre_canal: nombre,
               colorFondo_canal: colorFondo,
               colorTexto_canal: colorTexto,
            };
            const response = await request.post('/canales/canal/create', data);
            if (response && !response.error) {
               Swal.fire('Creado!', 'Este canal ha sido creado', 'success');
               reload();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleEditarCanal = () => {
      if (modifiedIndex === null) return;
      Swal.fire({
         title: '¿Está seguro de editar este canal?',
         text: 'Se editará el canal: ' + canales[modifiedIndex].channelName,
         icon: 'info',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Editar',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_canal: canales[modifiedIndex].channelId,
               nombre_canal: canales[modifiedIndex].channelName,
               colorFondo_canal: canales[modifiedIndex].channelColor,
               colorTexto_canal: canales[modifiedIndex].channelColorText,
            };
            const response = await request.post('/canales/canal/update', data);
            if (response && !response.error) {
               Swal.fire('Editado!', 'Canal editado con éxito', 'success');
               reload();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleInputChangeNombre = (e, index) => {
      const { value } = e.target;
      setCanales((prevCanales) => {
         const newCanales = [...prevCanales];
         newCanales[index].channelName = value;
         return newCanales;
      });
      setModifiedIndex(index);
   };

   const handleInputChangeColorFondo = (e, index) => {
      const { value } = e.target;
      setCanales((prevCanales) => {
         const newCanales = [...prevCanales];
         newCanales[index].channelColor = value;
         return newCanales;
      });
      setModifiedIndex(index);
   };

   const handleInputChangeColorTexto = (e, index) => {
      const { value } = e.target;
      setCanales((prevCanales) => {
         const newCanales = [...prevCanales];
         newCanales[index].channelColorText = value;
         return newCanales;
      });
      setModifiedIndex(index);
   };

   return {
      canales,
      nombre,
      colorFondo,
      colorTexto,
      modifiedIndex,
      setNombre,
      setColorFondo,
      setColorTexto,
      handleNuevoCanal,
      handleEliminarCanal,
      handleEditarCanal,
      handleInputChangeNombre,
      handleInputChangeColorFondo,
      handleInputChangeColorTexto,
   };
};

export default useChannels;
