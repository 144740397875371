import { useState, useContext } from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import Request from '../../api/httpClient';
import MotivosContext from '../../_helper/Motivos';

const useReasons = () => {
   const { motivos, setMotivos, getMotivos } = useContext(MotivosContext);
   const [modifiedIndex, setModifiedIndex] = useState(null);
   const request = new Request();

   const handleEliminarMotivo = (row) => {
      Swal.fire({
         title: '¿Está seguro de eliminar este motivo?',
         text: 'Se eliminará el motivo: ' + row.reasonName,
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Eliminar',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = { id_motivo: row.reasonId };

            const response = await request.post('/motivos/motivo/delete', data);
            if (response && !response.error) {
               Swal.fire('¡Eliminado!', 'Este motivo ha sido eliminado', 'success');
               getMotivos(); // Actualizar la lista
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleNuevoMotivo = (nuevoMotivo) => {
      Swal.fire({
         title: '¿Está seguro de crear un nuevo motivo?',
         text: 'Se creará el motivo: ' + nuevoMotivo,
         icon: 'info',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Crear',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = { nombre_motivo: nuevoMotivo };

            const response = await request.post('/motivos/motivo/create', data);
            if (response && !response.error) {
               Swal.fire('¡Creado!', 'Se ha creado un nuevo motivo', 'success');
               getMotivos(); // Actualizar la lista
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleEditarMotivo = () => {
      Swal.fire({
         title: '¿Está seguro de editar este motivo?',
         text: 'Se editará el motivo: ' + motivos[modifiedIndex].reasonName,
         icon: 'info',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Si, Editar',
         cancelButtonText: 'Cancelar',
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_motivo: motivos[modifiedIndex].reasonId,
               nombre_motivo: motivos[modifiedIndex].reasonName,
            };

            const response = await request.post('/motivos/motivo/update', data);
            if (response && !response.error) {
               Swal.fire('¡Editado!', 'Motivo editado con éxito', 'success');
               getMotivos(); // Actualizar la lista
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleInputChange = (e, index) => {
      const { value } = e.target;
      setMotivos((prevMotivos) => {
         const newMotivos = [...prevMotivos];
         newMotivos[index].reasonName = value;
         return newMotivos;
      });
      setModifiedIndex(index);
   };

   return {
      motivos,
      modifiedIndex,
      setModifiedIndex,
      handleEliminarMotivo,
      handleNuevoMotivo,
      handleEditarMotivo,
      handleInputChange,
   };
};

export default useReasons;
