import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {LogIn, Lock} from "react-feather";
import man from "../../../assets/images/user/user.png";

import {LI, UL, Image, P} from "../../../AbstractElements";
import {Fragment} from "react";
import UsuarioChangePassword from "../../../Components/Usuarios/UsuarioChangePassword";
import {toast} from "react-toastify";

const UserHeader = () => {
   const history = useNavigate();
   const [profile, setProfile] = useState("");
   const [name, setName] = useState("");
   const [role, setRole] = useState("");
   const roles = {
      A: "Administrador",
      S: "Asesor Externo",
      V: "Asesor Interno",
   };
   const authenticated = JSON.parse(localStorage.getItem("authenticated"));
   const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

   const [userDropdown, setUserDropDown] = useState(false);

   const [modalShow, setModalShow] = useState(false);
   const toggleShow = () => setModalShow(!modalShow);

   useEffect(() => {
      setProfile(localStorage.getItem("profileURL") || man);
      setName(
         localStorage.getItem("Name") ? localStorage.getItem("Name") : name
      );
      setRole(
         localStorage.getItem("Role") ? localStorage.getItem("Role") : role
      );
   }, []);

   const Logout = () => {
      localStorage.removeItem("profileURL");
      localStorage.removeItem("token");
      localStorage.removeItem("auth0_profile");
      localStorage.removeItem("Name");
      localStorage.removeItem("Type");
      localStorage.setItem("authenticated", false);
      localStorage.setItem("login", false);
      history(`/login`);
   };

   const reload = (message) => {
      toast.success(message, {
         position: toast.POSITION.BOTTOM_RIGHT,
         autoClose: 2000,
      });
   };

   return (
      <Fragment>
         <li className="profile-nav onhover-dropdown pe-0 py-0">
            <div
               className="media profile-media"
               onClick={() => setUserDropDown(!userDropdown)}
            >
               <Image
                  attrImage={{
                     className: "b-r-10 m-0",
                     src: `${authenticated ? auth0_profile.picture : profile}`,
                     alt: "",
                  }}
               />
               <div className="media-body">
                  <span>
                     {authenticated
                        ? auth0_profile.name
                        : name.split(" ").slice(0, 2).join(" ")}
                  </span>
                  <P attrPara={{className: "mb-0 font-roboto"}}>
                     {roles[role]} <i className="middle fa fa-angle-down"></i>
                  </P>
               </div>
            </div>

            {userDropdown && (
               <UL
                  attrUL={{
                     className: `simple-list profile-dropdown onhover-show-div ${
                        userDropdown ? "active" : ""
                     }`,
                  }}
               >
                  <LI attrLI={{onClick: toggleShow}}>
                     <Lock />
                     <span>Contraseña</span>
                  </LI>
                  <LI attrLI={{onClick: Logout}}>
                     <LogIn />
                     <span>Cerrar sesión</span>
                  </LI>
               </UL>
            )}
         </li>
         {modalShow && (
            <UsuarioChangePassword
               modal={true}
               title={"Cambiar contraseña"}
               toggle={toggleShow}
               size="xs"
               reload={reload}
               id_usuario={localStorage.getItem("user_id") || 0}
               email={localStorage.getItem("userEmail") || ""}
            />
         )}
      </Fragment>
   );
};

export default UserHeader;
