import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Input } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../AbstractElements';
import HeaderCard from '../Common/Component/HeaderCard';
import useChannels from '../../Hooks/channels/useChannels';

const CanalesPage = () => {
   const {
      canales,
      nombre,
      colorFondo,
      colorTexto,
      modifiedIndex,
      setNombre,
      setColorFondo,
      setColorTexto,
      handleNuevoCanal,
      handleEditarCanal,
      handleEliminarCanal,
      handleInputChangeNombre,
      handleInputChangeColorFondo,
      handleInputChangeColorTexto,
   } = useChannels();

   return (
      <Fragment>
         <Breadcrumbs parent="Gestión" title="Canales" mainTitle="Canales" />
         <Container fluid={true}>
            <Row>
               <Col xl="6" className="box-col-6">
                  <Card>
                     <HeaderCard title="Canales" />
                     <CardBody>
                        {canales.map((canal, index) => (
                           <FormGroup className="row" key={index}>
                              <Col xs="6" md="6">
                                 <Input
                                    value={canal.channelName}
                                    type="text"
                                    onChange={(e) => handleInputChangeNombre(e, index)}
                                 />
                              </Col>
                              <Col xs="3" md="3" style={{ display: 'flex' }}>
                                 <Input
                                    className="form-control-color m-l-10"
                                    type="color"
                                    value={canal.channelColor}
                                    onChange={(e) =>
                                       handleInputChangeColorFondo(e, index)
                                    }
                                 />
                                 <Input
                                    className="form-control-color m-l-10"
                                    type="color"
                                    value={canal.channelColorText}
                                    onChange={(e) =>
                                       handleInputChangeColorTexto(e, index)
                                    }
                                 />
                              </Col>
                              <Col xs="3" md="3" style={{ display: 'flex' }}>
                                 <Btn
                                    attrBtn={{
                                       color: 'secondary',
                                       style: {
                                          padding: '5px 10px',
                                          marginTop: '3px',
                                          marginLeft: '10px',
                                          width: '3rem',
                                       },
                                       onClick: () => handleEliminarCanal(canal),
                                    }}>
                                    <i className="fa fa-trash"></i>
                                 </Btn>
                                 {modifiedIndex === index && (
                                    <Btn
                                       attrBtn={{
                                          color: 'success',
                                          style: {
                                             padding: '5px 10px',
                                             marginTop: '3px',
                                             marginLeft: '10px',
                                             width: '3rem',
                                          },
                                          onClick: () => handleEditarCanal(),
                                       }}>
                                       <i className="fa fa-check"></i>
                                    </Btn>
                                 )}
                              </Col>
                           </FormGroup>
                        ))}
                        <FormGroup className="row">
                           <Col xs="6" md="6">
                              <Input
                                 id="nuevo"
                                 type="text"
                                 value={nombre}
                                 placeholder="Nuevo canal"
                                 onChange={(e) => setNombre(e.target.value)}
                              />
                           </Col>
                           <Col xs="3" md="3" style={{ display: 'flex' }}>
                              <Input
                                 className="form-control-color m-l-10"
                                 type="color"
                                 value={colorFondo}
                                 onChange={(e) => setColorFondo(e.target.value)}
                              />
                              <Input
                                 className="form-control-color m-l-10"
                                 type="color"
                                 value={colorTexto}
                                 onChange={(e) => setColorTexto(e.target.value)}
                              />
                           </Col>
                           <Col xs="3" md="3" style={{ display: 'flex' }}>
                              <Btn
                                 attrBtn={{
                                    color: 'info',
                                    style: {
                                       padding: '5px 10px',
                                       marginTop: '3px',
                                       marginLeft: '10px',
                                       width: '3rem',
                                    },
                                    onClick: handleNuevoCanal,
                                 }}>
                                 <i className="fa fa-plus"></i>
                              </Btn>
                           </Col>
                        </FormGroup>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </Container>
      </Fragment>
   );
};

export default CanalesPage;
