import React, {useContext, useEffect, useState, useMemo} from "react";
import {toast} from "react-toastify";
import moment from "moment";
import {Container, Row, Col, Card, Form, FormGroup, Input} from "reactstrap";
import Swal from "sweetalert2";
import {Breadcrumbs} from "../../AbstractElements";
import Request from "../../api/httpClient";
import DataTableComponent from "../Tables/DataTable/DataTableComponent";
import DesarrollosContext from "../../_helper/Desarrollos";
import EmbudosContext from "../../_helper/Embudos";
import ToolbarButtons from "../shared/Buttons/ToolbarButtons";
import ProspectToggle from "../Prospectos/components/ProspectToggle/ProspectToggle";
import BrokerEditModal from "./components/BrokerEditModal";
import BrokerCreateModal from "./components/BrokerCreateModal";
import {BrokersColumns} from "../../Data/Table/BrokersColumns";
import ModalContainer from "../shared/ModalContainer";
import FilterRangeDate from "../shared/Filters/FilterRangeDate";

const request = new Request();

const Brokers = () => {
   const [search, setSearch] = useState("");
   const {embudos, loadingEmbudos} = useContext(EmbudosContext);
   const [brokers, setBrokers] = useState([]);
   const {desarrollos} = useContext(DesarrollosContext);
   const [prospecto, setProspecto] = useState(null);
   const [loadingProspectos, setLoadingProspectos] = useState(false);
   const [modalCreate, setModalCreate] = useState(false);
   const toggleCreate = () => setModalCreate(!modalCreate);
   const [modalEdit, setModalEdit] = useState(false);
   const toggleEdit = () => setModalEdit(!modalEdit);
   const [desarrolloSelec, setDesarrolloSelec] = useState({});
   const [canales, setCanales] = useState([]);
   const [loadingCanales, setLoadingCanales] = useState(false);
   const [otrosUsuariosInfo, setOtrosUsauriosInfo] = useState(false);
   const userRole = localStorage.getItem("Role");

   const [showModal, setShowModal] = useState(false);
   const handleShowModal = () => setShowModal(!showModal);
   const [from, setFrom] = useState(moment().clone().startOf("month").toDate());
   const [to, setTo] = useState(moment().clone().endOf("month").toDate());

   const handleChange = (event) => {
      setSearch(event.target.value);
   };

   const filteredBrokers = useMemo(() => {
      if (!search) return brokers;

      return [...brokers].filter((broker) =>
         Object.values(broker).some((value) =>
            String(value).toLowerCase().includes(search.toLowerCase())
         )
      );
   }, [brokers, search]);

   const getBrokers = async () => {
      setBrokers([]);
      setLoadingProspectos(true);
      const data = {
         id_usuario: otrosUsuariosInfo
            ? 0
            : localStorage.getItem("user_id") || 0,
      };

      const response = await request.post("/brokers", data);
      if (response && !response.error) {
         if (response && !response.empty) {
            setBrokers(response);
         } else {
            setBrokers([]);
            setLoadingProspectos(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setLoadingProspectos(false);
   };

   const fetchReport = async () => {
      const data = {
         id_usuario: otrosUsuariosInfo
            ? 0
            : localStorage.getItem("user_id") || 0,
         from: moment(from).format("YYYY-MM-DD 00:00:00"),
         to: moment(to).format("YYYY-MM-DD 23:59:59"),
      };

      const response = await request.post("/brokers/report", data);
      return response;
   };

   const getCanales = async () => {
      setCanales([]);
      setLoadingCanales(true);
      const response = await request.get("/canales/get/all");
      if (response && !response.error) {
         if (response && !response.empty) {
            const fuentes = response.map((item) => {
               return {
                  label: item.channelName,
                  value: item.channelName,
               };
            });
            setCanales(fuentes);
         } else {
            setCanales([]);
            setLoadingCanales(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setLoadingCanales(false);
   };

   const handleEditarProspecto = (row) => {
      setProspecto(row);
      toggleEdit();
   };

   const handleEliminarProspecto = (row) => {
      Swal.fire({
         title: "Está seguro de eliminar este prospecto?",
         text: "Se eliminará el prospecto: " + row.client,
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Eliminar",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_prospecto: row.pkclient,
            };

            const response = await request.post(
               "/prospectos/prospecto/delete",
               data
            );
            if (response && !response.error) {
               Swal.fire(
                  "Eliminado!",
                  "Este prospecto ha sido eliminado",
                  "success"
               );
               getBrokers();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const reload = (refresh, message, id_desarrollo) => {
      if (desarrollos.length > 0) {
         const desarrolloSeleccionado = desarrollos.find(
            (desarrollo) => desarrollo.IDdesarrollo === id_desarrollo
         );
         if (desarrolloSeleccionado) {
            setDesarrolloSelec(desarrolloSeleccionado);
         }
      }
      toast.success(message, {
         position: toast.POSITION.BOTTOM_RIGHT,
         autoClose: 2000,
      });
      if (refresh) getBrokers();
   };

   const tableColumns_prospectos = BrokersColumns(
      handleEditarProspecto,
      handleEliminarProspecto,
      otrosUsuariosInfo
   );

   useEffect(() => {
      if (desarrollos.length > 0) {
         setDesarrolloSelec(desarrollos[0]);
      }
   }, [desarrollos]);

   useEffect(() => {
      if (desarrollos.length > 0 && Object.keys(desarrolloSelec).length !== 0) {
         getBrokers();
         getCanales();
      }
   }, [desarrolloSelec]);

   useEffect(() => {
      if (desarrollos.length > 0 && Object.keys(desarrolloSelec).length !== 0) {
         getBrokers();
      }
   }, [otrosUsuariosInfo]);

   return (
      <>
         <Breadcrumbs parent="Gestión" title="Brokers" mainTitle="Brokers" />
         <Container fluid={true}>
            <Card style={{minHeight: `calc(90vh - 140px)`}}>
               <Row>
                  <Col>
                     <Row className="m-20">
                        <Col sm="5">
                           <div className="product-search feature-products">
                              <Form>
                                 <FormGroup className="m-0 form-group search-product">
                                    <Input
                                       className="form-control"
                                       type="text"
                                       placeholder="Buscar..."
                                       value={search}
                                       onChange={handleChange}
                                    />
                                    <i className="fa fa-search" />
                                 </FormGroup>
                              </Form>
                           </div>
                        </Col>
                        {userRole === "A" && (
                           <Col>
                              <Row>
                                 <ProspectToggle
                                    setOtherUsersInfo={setOtrosUsauriosInfo}
                                    otherUsersInfo={otrosUsuariosInfo}
                                 />
                              </Row>
                           </Col>
                        )}
                        <Col sm="5">
                           <ToolbarButtons
                              filename="Brokers"
                              showModal={handleShowModal}
                           />
                        </Col>
                     </Row>
                     <Col className="m-l-20 m-r-20 m-b-8">
                        <DataTableComponent
                           tableColumns={tableColumns_prospectos}
                           tableRows={filteredBrokers}
                           loadingData={loadingProspectos}
                           pagination={true}
                           scrollHeight={"calc(88vh - 230px)"}
                           minHeight={`calc(84vh - 230px)`}
                        />
                     </Col>
                  </Col>
               </Row>
            </Card>
         </Container>
         {modalCreate && (
            <BrokerCreateModal
               modal={true}
               title={"Nuevo broker"}
               toggle={toggleCreate}
               size="lg"
               reload={reload}
               p_desarrollos={desarrollos}
               p_desarrollo={desarrolloSelec}
               fuentes={canales}
               loadingFuentes={loadingCanales}
               etapas={embudos.map((item) => {
                  return {
                     label: item.funnelName,
                     value: item.funnelId,
                  };
               })}
               loadingEtapas={loadingEmbudos}
            />
         )}
         {modalEdit && (
            <BrokerEditModal
               modal={true}
               title={"Editar broker"}
               toggle={toggleEdit}
               size="lg"
               reload={reload}
               prospecto={prospecto}
               p_desarrollos={desarrollos}
               fuentes={canales}
               loadingFuentes={loadingCanales}
               etapas={embudos.map((item) => {
                  return {
                     label: item.funnelName,
                     value: item.funnelId,
                  };
               })}
               loadingEtapas={loadingEmbudos}
            />
         )}
         {showModal && (
            <ModalContainer modal={true} open={handleShowModal} size="md">
               <FilterRangeDate
                  onClose={handleShowModal}
                  onExcelClick={fetchReport}
                  filename="Brokers"
                  to={to}
                  from={from}
                  setTo={setTo}
                  setFrom={setFrom}
               />
            </ModalContainer>
         )}
      </>
   );
};

export default Brokers;
