import { useContext, useEffect, useState } from 'react';
import Request from '../../api/httpClient';
import DesarrollosContext from '../../_helper/Desarrollos';

const useIndex = () => {
   const coloresWidgets = [
      'primary',
      'secondary',
      'warning',
      'success',
      'secondary',
      'primary',
      'success',
      'warning',
   ];
   const [totalProspectos, setTotalProspectos] = useState(
      Array(6).fill({
         nombre: '...',
         total: 0,
         color: 'secondary',
      })
   );
   const [fuenteProspecto, setFuenteProspecto] = useState({});
   const [totalUsuarios, setTotalUsuarios] = useState([]);
   const { desarrollos } = useContext(DesarrollosContext);
   const [desarrolloSelec, setDesarrolloSelect] = useState(0);
   const role = localStorage.getItem('Role');
   const request = new Request();

   const getTotalProspectos = async () => {
      setTotalProspectos(Array(6).fill({ nombre: '...', total: 0, color: 'secondary' }));
      const data = {
         id_desarrollo: desarrolloSelec,
         id_usuario: localStorage.getItem('user_id') || 0,
      };

      const response = await request.post('/reportes/get/totalProspectos', data);
      if (response && !response.error) {
         setTotalProspectos(
            response.map((tProspecto, i) => ({
               nombre: tProspecto.nombre,
               total: tProspecto.cantidad,
               color: coloresWidgets[i],
               icon: 'customers',
            }))
         );
      } else {
         console.error(response?.message);
      }
   };

   const getFuenteProspectos = async () => {
      setFuenteProspecto({});
      const data = {
         id_desarrollo: desarrolloSelec,
         id_usuario: localStorage.getItem('user_id') || 0,
      };

      const response = await request.post('/reportes/get/fuenteProspectos', data);
      if (response && !response.error) {
         CambiarFormatoFuenteProspectos(response);
         setFuenteProspecto(response);
      } else {
         console.error(response?.message);
      }
   };

   const getTotalUsuarios = async () => {
      const response = await request.get('/reportes/get/totalUsuarios');
      if (response && !response.error) {
         setTotalUsuarios(response);
      } else {
         console.error(response?.message);
      }
   };

   const CambiarFormatoFuenteProspectos = (data) => {
      setFuenteProspecto({
         series: data.map((item) => item.cantidad),
         options: {
            chart: { width: 380, type: 'pie', toolbar: { show: false } },
            labels: data.map((item) => item.origin),
            colors: ['#f08080', '#00ced1', '#00bfff', '#3cb371', '#dda0dd', '#ffa07a'],
            responsive: [
               {
                  breakpoint: 480,
                  options: { chart: { width: 200 }, legend: { position: 'bottom' } },
               },
            ],
         },
      });
   };

   const onDesarrolloSelected = (option) => setDesarrolloSelect(option.IDdesarrollo);

   useEffect(() => {
      getTotalProspectos();
      getFuenteProspectos();
   }, [desarrolloSelec]);

   useEffect(() => {
      getTotalUsuarios();
   }, []);

   return {
      totalProspectos,
      fuenteProspecto,
      totalUsuarios,
      role,
      desarrollos,
      onDesarrolloSelected,
   };
};

export default useIndex;
