import React from 'react';
import Select from 'react-select';
import { Form, FormGroup, Input, Label, Row, Col, Button } from 'reactstrap';
import useZapierEditForm from '../../../../Hooks/zapier/useZapierEditForm';

const ZapiersEditForm = ({ lead, toggle, fetchZapier }) => {
   const {
      isSubmitDisabled,
      isLoading,
      internalAdvisors,
      setSelectedInternalAdvisor,
      handleSubmit,
   } = useZapierEditForm({ lead, toggle, fetchZapier });

   return (
      <Form>
         <div className="project-form-modal">
            <FormGroup>
               <Row>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Nombre:</Label>
                     <Input defaultValue={lead.Nombre} id="nombre" type="text" disabled />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Apellido:</Label>
                     <Input
                        defaultValue={lead.Apellido}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Teléfono:</Label>
                     <Input
                        defaultValue={lead.WhatsApp}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Email:</Label>
                     <Input defaultValue={lead.Email} id="nombre" type="text" disabled />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Origen:</Label>
                     <Input defaultValue={lead.Origen} id="nombre" type="text" disabled />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Campaña:</Label>
                     <Input
                        defaultValue={lead.Campanya}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">AdSet:</Label>
                     <Input defaultValue={lead.AdSet} id="nombre" type="text" disabled />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">AdName:</Label>
                     <Input defaultValue={lead.AdName} id="nombre" type="text" disabled />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Plataforma:</Label>
                     <Input
                        defaultValue={lead.Platform}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Ciudad:</Label>
                     <Input defaultValue={lead.Ciudad} id="nombre" type="text" disabled />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Interes:</Label>
                     <Input
                        defaultValue={lead.Interes}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Presupuesto:</Label>
                     <Input
                        defaultValue={lead.Presupuesto}
                        id="nombre"
                        type="text"
                        disabled
                     />
                  </Col>
                  <Col xl="4" sm="12">
                     <Label className="col-form-label text-start">Asesor interno:</Label>
                     <Select
                        options={internalAdvisors}
                        value={internalAdvisors.find((a) => a.id === lead.pkuser)}
                        placeholder={'Seleccionar asesor interno'}
                        onChange={(e) => setSelectedInternalAdvisor(e?.value)}
                        isLoading={isLoading}
                        styles={{
                           menu: (provided) => ({
                              ...provided,
                              zIndex: 3,
                           }),
                        }}
                     />
                  </Col>
               </Row>
            </FormGroup>
            <div className="w-100 d-flex justify-content-end btn-showcase">
               <Button color="secondary" onClick={toggle} className="m-r-10">
                  Cancelar
               </Button>
               <Button color="primary" disabled={isSubmitDisabled} onClick={handleSubmit}>
                  {isSubmitDisabled ? 'Guardando...' : 'Guardar'}
               </Button>
            </div>
         </div>
      </Form>
   );
};

export default ZapiersEditForm;
