import EmbudosContext from "./index";
import React, {useEffect, useState} from "react";
import Request from "../../api/httpClient";

const EmbudosProvider = (props) => {
  const [embudos, setEmbudos] = useState([]);
  const [loadingEmbudos, setLoadingEmbudos] = useState(true);
  const request = new Request();

  const getEmbudos = async () => {
    const response = await request.get("/embudos/get/all");
    if (response && !response.error) {
      if (response) {
        setEmbudos(response);
      } else {
        console.error(response.message);
      }
    } else {
      console.error(response.message);
    }
    setLoadingEmbudos(false);
  };

  useEffect(() => {
    getEmbudos();
  }, []);

  return (
    <EmbudosContext.Provider
      value={{embudos, setEmbudos, loadingEmbudos, getEmbudos}}
    >
      {props.children}
    </EmbudosContext.Provider>
  );
};

export default EmbudosProvider;
