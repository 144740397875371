import React, { Fragment } from 'react';
import { Container, Row, Col, Card, Form, FormGroup, Input } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import DataTableComponent from '../Tables/DataTable/DataTableComponent';
import DisponibilidadFormEdit from './DisponibilidadEditForm';
import useAvailability from '../../Hooks/availability/useAvailability';
import AvailabilityColumns from '../../Data/Table/AvailabilityColumns';

const DisponibilidadPage = () => {
   const {
      searchTerm,
      handleInputChange,
      filteredData,
      loadingDepartamentos,
      modalEdit,
      toggleEdit,
      departamento,
      handleEditarDpto,
      desarrolloSelec,
      handleSelectChange,
      desarrollos,
      reload,
   } = useAvailability();

   const tableColumns = AvailabilityColumns({ handleEditarDpto });

   return (
      <Fragment>
         <Breadcrumbs
            parent="Desarrollos"
            title="Disponibilidad"
            mainTitle="Disponibilidad"
         />
         <Container fluid={true}>
            <Row>
               <Col md="12" className="project-list">
                  <Card style={{ minHeight: 'calc(90vh - 140px)' }}>
                     <Row className="m-20">
                        <Col sm="5">
                           <div className="product-search feature-products">
                              <Form>
                                 <FormGroup className="m-0 form-group search-product">
                                    <Input
                                       className="form-control"
                                       type="text"
                                       placeholder="Buscar..."
                                       value={searchTerm}
                                       onChange={handleInputChange}
                                    />
                                    <i className="fa fa-search"></i>
                                 </FormGroup>
                              </Form>
                           </div>
                        </Col>
                        <Col
                           sm="6"
                           className="d-flex justify-content-start align-items-center">
                           <FormGroup className="w-50">
                              <Input
                                 type="select"
                                 value={desarrolloSelec.IDdesarrollo || ''}
                                 onChange={handleSelectChange}
                                 className="form-control">
                                 {desarrollos.map((des) => (
                                    <option
                                       key={des.IDdesarrollo}
                                       value={des.IDdesarrollo}>
                                       {des.nomDesarrollo}
                                    </option>
                                 ))}
                              </Input>
                           </FormGroup>
                        </Col>
                     </Row>
                     <Col className="m-l-20 m-r-20 m-b-8">
                        <DataTableComponent
                           tableColumns={tableColumns}
                           tableRows={filteredData}
                           loadingData={loadingDepartamentos}
                           pagination={true}
                           scrollHeight={'calc(84vh - 230px)'}
                           minHeight={'calc(82vh - 230px)'}
                        />
                     </Col>
                  </Card>
               </Col>
            </Row>
         </Container>
         {modalEdit && (
            <DisponibilidadFormEdit
               modal={true}
               title={'Editar departamento'}
               toggle={toggleEdit}
               size="lg"
               reload={reload}
               departamento={departamento}
            />
         )}
      </Fragment>
   );
};

export default DisponibilidadPage;
