import "react-bootstrap-typeahead/css/Typeahead.css";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import ZapiersEditForm from "../Forms/ZapierEditForm";

const ZapierEditModal = ({modal, toggle, lead, fetchZapier}) => {
   return (
      <Modal isOpen={modal} toggle={toggle} size="xl" centered>
         <ModalHeader toggle={toggle}>Detalle</ModalHeader>
         <ModalBody
            style={{
               minHeight: "400px",
            }}
         >
            <ZapiersEditForm
               lead={lead}
               toggle={toggle}
               fetchZapier={fetchZapier}
            />
         </ModalBody>
      </Modal>
   );
};

export default ZapierEditModal;
