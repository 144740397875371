// Project

export const Add = 'Add';

//Buttons

export const DefaultButtons = 'Default buttons';
export const BootstrapStateButtons = 'Bootstrap state buttons';
export const PrimaryButton = 'Primary Button';
export const SecondaryButton = 'Secondary Button';
export const SuccessButton = 'Success Button';
export const InfoButton = 'Info Button';
export const WarningButton = 'Warning Button';
export const DangerButton = 'Danger Button';
export const LightButton = 'Light Button';
export const LargeButtons = 'Large buttons';
export const SmallButtons = 'Small buttons';
export const ExtraSmallButtons = 'Extra Small buttons';
export const ActiveButtons = 'Active Buttons';
export const DisabledButtons = 'Disabled buttons';
export const CustomStateButtons = 'Custom state buttons';
export const OutlineButtons = 'outline buttons';
export const BoldBorderOutlineButtons = 'bold Border outline buttons';
export const OutlineLargeButtons = 'outline Large buttons';
export const OutlineSmallButtons = 'outline small buttons';
export const OutlineExtraSmallButtons = 'Outline extra small buttons';
export const DisabledOutlineButtons = 'Disabled outline buttons';
export const GradienButtons = 'Gradien buttons';
export const BasicButtonGroup = 'Basic button group';
export const EdgesButtonGroup = 'Edges button group';
export const FlatButtonGroup = 'Flat button group';
export const LargeButtonGroup = 'large Button group';
export const LargeEdgesButtonGroup = 'large Edges Button group';
export const OutlineCustomButtonGroup = 'Outline Custom button group';
export const OutlineButtonGroup = 'Outline button group';
export const OutlineEdgesButton = 'Outline Edges button';
export const OutlineFlatButton = 'Outline flat button';
export const RadioButtonGroup = 'Radio button group';
export const CheckBoxButtonGroup = 'Check Box button group';
export const Nesting = 'Nesting';
export const Vertical = 'Vertical';
export const Left = 'Left';
export const Middle = 'Middle';
export const Right = 'Right';

// layout
export const Loading = 'Loading...';
