// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import Request from "../../api/httpClient";

const request = new Request();

const useTracing = (prospect) => {
   const [isLoading, setIsLoading] = useState(false);
   const [tracings, setTracings] = useState([]);
   const [tracing, setTracing] = useState(null);
   const [leftTab, setLeftTab] = useState("1");
   const [showModal, setShowModal] = useState({
      create: false,
      edit: false,
   });

   const handleModal = (type) => {
      setShowModal((prev) => ({
         ...prev,
         [type]: !prev[type],
      }));
   };

   const fetchTracings = async () => {
      setTracings([]);
      setIsLoading(true);

      const data = {
         id_prospecto: prospect.pkclient,
      };
      const response = await request.post(
         "/prospectos/prospecto/seguimiento/get",
         data
      );
      if (response && !response.error) {
         if (response && !response.empty) {
            setTracings(response);
            if (response.length > 0) {
               setLeftTab(response[0].pkbinnacle);
               setTracing(response[0]);
            }
         } else {
            setTracings([]);
            setIsLoading(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }

      setIsLoading(false);
   };

   const reload = (message) => {
      toast.success(message, {
         position: toast.POSITION.BOTTOM_RIGHT,
         autoClose: 2000,
      });
      fetchTracings();
   };

   useEffect(() => {
      fetchTracings();
   }, []);

   return {
      handleModal,
      isLoading,
      tracings,
      tracing,
      leftTab,
      setLeftTab,
      setTracing,
      showModal,
      reload,
   };
};

export default useTracing;
