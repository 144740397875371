// eslint-disable-next-line no-unused-vars
import React, {useState, useEffect, useMemo} from "react";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import Request from "../../api/httpClient";
import {UsersColumns} from "../../Data/Table/UsersColumns";

const request = new Request();

const USERS_TYPE = [
   {id: 0, label: "Administradores", value: "Todos", type: "A"},
   {id: 1, label: "Asesores internos", value: "Asesores internos", type: "V"},
   {id: 2, label: "Asesores externos", value: "Asesores externos", type: "S"},
   {id: 3, label: "Agencia", value: "Agencia", type: "M"},
];

const useUsers = () => {
   const [search, setSearch] = useState("");
   const [users, setUsers] = useState([]);
   const [user, setUser] = useState(null);
   const [type, setType] = useState("A");
   const [isLoading, setIsLoading] = useState(false);
   const [showModal, setShowModal] = useState({
      create: false,
      edit: false,
      change: false,
      prospectList: false,
   });

   const handleModal = (type) => {
      setShowModal((prev) => ({
         ...prev,
         [type]: !prev[type],
      }));
   };

   const handleChange = (event) => {
      setSearch(event.target.value);
   };

   const filteredUsers = useMemo(() => {
      if (!search) return users;

      return [...users].filter((user) =>
         Object.values(user).some((value) =>
            String(value).toLowerCase().includes(search.toLowerCase())
         )
      );
   }, [users, search]);

   const fetchUsers = async () => {
      setUsers([]);
      setIsLoading(true);

      const selectedType = USERS_TYPE.find((u) => u.type === type);

      const data = {
         role: selectedType?.type || "A",
      };

      const response = await request.post("/usuarios/get/all", data);
      if (response && !response.error) {
         if (response && !response.empty) {
            setUsers(response);
         } else {
            setUsers([]);
            setIsLoading(false);
            console.error(response.message);
         }
      } else {
         console.error(response.message);
      }
      setIsLoading(false);
   };

   const handleUserDelete = (row) => {
      Swal.fire({
         title: "Está seguro de eliminar este usuario?",
         text: "Se eliminará el usuario: " + row.email,
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Desactivar",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_usuario: row.pkuser,
               status: 0,
            };

            const response = await request.post(
               "/usuarios/usuario/status",
               data
            );
            if (response && !response.error) {
               Swal.fire(
                  "Desactivado!",
                  "Este usuario ha sido desactivado",
                  "success"
               );
               fetchUsers();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handleUserRecovery = (row) => {
      Swal.fire({
         title: "Está seguro de activar este usuario?",
         text: "Se activará el usuario: " + row.email,
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Si, Activar",
         cancelButtonText: "Cancelar",
         reverseButtons: true,
      }).then(async (result) => {
         if (result.isConfirmed) {
            let data = {
               id_usuario: row.pkuser,
               status: 1,
            };

            const response = await request.post(
               "/usuarios/usuario/status",
               data
            );
            if (response && !response.error) {
               Swal.fire(
                  "Activado!",
                  "Este usuario ha sido activado",
                  "success"
               );
               fetchUsers();
            } else {
               toast.error(response.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
               });
            }
         }
      });
   };

   const handlePasswordEdit = (row) => {
      setUser(row);
      handleModal("change");
   };

   const handleUserEdit = (row) => {
      setUser(row);
      handleModal("edit");
   };

   const handleProspectList = (row) => {
      setUser(row);
      handleModal("prospectList");
   };

   const columns = UsersColumns(
      type,
      handleUserDelete,
      handleUserRecovery,
      handleUserEdit,
      handlePasswordEdit,
      handleProspectList
   );

   useEffect(() => {
      fetchUsers();
   }, [type]);

   return {
      search,
      users,
      user,
      isLoading,
      handleChange,
      filteredUsers,
      fetchUsers,
      showModal,
      handleModal,
      columns,
      USERS_TYPE,
      type,
      setType,
   };
};

export default useUsers;
